import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledSocialMediaWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$1',
  cursor: 'pointer',
});

export const StyledIconContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  border: '1px solid transparent',
  '&:hover': {
    border: '2px solid $white',
  },
  variants: {
    isCopied: {
      true: {
        border: '2px solid $white',
      },
    },
  },
});

export const StyledShareIconWrapper = styled(Box, {
  cursor: 'pointer',
  color: '$blue',
  variants: {
    isOpen: {
      true: {
        color: '$white',
      },
    },
  },
});
