import { memo, useMemo, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Flag } from '~components/atoms/Flag';
import { Text } from '~components/atoms/Typography';
import { CountryName } from '~constants/flag';
import { useEventUpdatesListener } from '~hooks/useEventUpdatesListener';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';

import { useEventsListItem } from './hooks/useEventsListItem';
import { MAIN_PAGE_TABS } from './helpers';
import { MainEventLiveData } from './MainEventLiveData';
import { MainEventPrematchData } from './MainEventPrematchData';
import { MarketsEventData } from './MarketsEventData';
import { MobileEventFooterData } from './MobileEventFooterData';
import { MobileLiveEventData } from './MobileLiveEventData';
import { MobilePrematchEventData } from './MobilePrematchEventData';

export interface EventsListItemProps {
  eventData: SportEventItem;
  mainMarkets: MainMarket[];
  isLive?: boolean;
  isFirst?: boolean;
  maxMarketsCount?: number;
}

export const EventsListItem = memo(
  ({
    eventData,
    mainMarkets,
    isLive,
    isFirst,
    maxMarketsCount,
  }: EventsListItemProps) => {
    const { isMobileOrTablet } = useMedia();
    const { mobileMainPageTab } = useAppSelector((state) => state.mobileState);
    const [localEventData, setLocalEventData] = useState(eventData);
    const { marketsData } = useEventsListItem({
      eventData: localEventData,
      mainMarkets,
      isFirst,
      maxMarketsCount,
    });

    useEventUpdatesListener({
      event: localEventData,
      onUpdate: setLocalEventData,
    });

    const { countryName, leagueName, code, countryCode } = localEventData;

    const eventContent = useMemo(() => {
      if (isMobileOrTablet) {
        return mobileMainPageTab === MAIN_PAGE_TABS.LIVE_HIGHLIGHTS ? (
          <MobileLiveEventData eventData={localEventData} />
        ) : (
          <MobilePrematchEventData eventData={localEventData} />
        );
      } else {
        return isLive ? (
          <MainEventLiveData eventData={localEventData} />
        ) : (
          <MainEventPrematchData eventData={localEventData} />
        );
      }
    }, [isMobileOrTablet, isLive, mobileMainPageTab, localEventData]);

    if (!localEventData || !marketsData) return null;

    return (
      <Box
        flexCol
        fullWidth
        css={{
          background: '$blackDarker',
          borderRadius: '$12',
        }}
      >
        <Box
          fullWidth
          flexRow
          alignCenter
          justifyContentStart
          gap={2}
          css={{ p: '$2 $4', borderBottom: '1px solid $gray' }}
        >
          <Flag
            countryName={countryName as CountryName}
            code={code || (countryCode as string)}
          />
          <Text level="14-24">
            {countryName} - {leagueName}
          </Text>
        </Box>
        <Box flexCol fullWidth>
          <Box
            fullWidth
            gap={2}
            css={{
              p: '$1 $2',
              display: 'grid',
              gridTemplateColumns: 'minmax(0, 1fr) min-content',
            }}
          >
            {eventContent}
            <Box>
              <MarketsEventData
                isLive={isLive}
                marketsData={marketsData}
                eventId={eventData?.id}
                mainMarkets={mainMarkets}
                eventData={eventData}
              />
            </Box>
          </Box>
          {isMobileOrTablet && (
            <MobileEventFooterData eventData={eventData} isLive={isLive} />
          )}
        </Box>
      </Box>
    );
  },
);
