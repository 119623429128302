import { useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { Message } from '~components/atoms/Message';
import { useRouterQuery } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';

import {
  BETSLIP_ERRORS,
  BETSLIP_VALUES_MAP_OPTIONS,
  getBetslipErrors,
  MIN_SYSTEM_BET_EVENTS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../../constants';
import { useBetslipErrors } from '../../hooks/useBetslipErrors';

interface BetslipErrorsProps {
  isQuickBet: boolean;
}

type StakeLimits = {
  minStakeLimit?: number;
  maxStakeLimit?: number;
};

export const BetslipErrors = ({ isQuickBet }: BetslipErrorsProps) => {
  const { localizedError, localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { betslipErrors, stakeType, balanceChangesMap } = useAppSelector(
    (state) => state.betslip,
  );
  const { currency } = useAppSelector((state) => state.settings);
  const { partnerLimits } = useAppSelector((state) => state.settings);
  const { sportMaxSelectionCount, sportMinSelectionCount } = partnerLimits;
  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const { navigateWithHistory } = useRouterQuery();

  useBetslipErrors(isQuickBet);

  // For Single bets, we show amount at the BetslipStake level, not in footer
  const getMinMaxStakeLimits = (): StakeLimits => {
    // If it is a quick bet, we will only have one selection in balance change map
    if (isQuickBet) {
      return Object.values(balanceChangesMap)[0] || {};
    }

    if (stakeType !== SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
      return balanceChangesMap[BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED] || {};
    }

    return {};
  };

  const { minStakeLimit = 0, maxStakeLimit = 0 } = getMinMaxStakeLimits();

  const errorsMap = useMemo(
    () =>
      getBetslipErrors({
        localized,
        localizedError,
        dispatch,
        navigateWithHistory,
        websiteCurrency: currency,
        isMobileOrTablet,
        minStakeLimit,
        maxStakeLimit,
        sportMaxSelectionCount,
        sportMinSelectionCount: isSystemBet
          ? MIN_SYSTEM_BET_EVENTS
          : sportMinSelectionCount,
      }),
    [
      isSystemBet,
      currency,
      isMobileOrTablet,
      minStakeLimit,
      maxStakeLimit,
      sportMaxSelectionCount,
      sportMinSelectionCount,
    ],
  );

  if (!betslipErrors.length) return null;

  return (
    <Box
      flexCol
      css={{
        gap: '$1',
        padding: '$4',
      }}
    >
      {betslipErrors.map((errorKey: BETSLIP_ERRORS) => {
        const errorData = errorsMap[errorKey];

        return <Message key={errorKey} {...errorData} />;
      })}
    </Box>
  );
};
