import React, { memo } from 'react';

import {
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionRoot,
  MultiAccordionTrigger,
} from '../../MultiAccordion';
import { CountryMenu } from '../CountryMenu/CountryMenu';
import { useSportMenu } from '../hooks/useSportMenu';

import { SportMenuGroups } from './SportMenuGroups';

export const SportMenu = memo(() => {
  const {
    sports,
    sportMenuRole,
    openSports,
    handleOnValueChange,
    getCountriesDataMemo,
  } = useSportMenu();

  if (!sports) {
    return null;
  }

  return (
    <>
      <SportMenuGroups />
      <MultiAccordionRoot
        type="multiple"
        role={sportMenuRole}
        value={openSports}
        onValueChange={handleOnValueChange}
      >
        {sports.map(({ id, name, events, countries }) => (
          <MultiAccordionItem
            value={id.toString()}
            key={id}
            role={sportMenuRole}
          >
            <MultiAccordionTrigger
              title={name}
              icon={id}
              count={events}
              role={sportMenuRole}
            />
            <MultiAccordionContent role={sportMenuRole}>
              <CountryMenu
                sportId={id}
                initialCountries={countries}
                countries={getCountriesDataMemo(id)}
              />
            </MultiAccordionContent>
          </MultiAccordionItem>
        ))}
      </MultiAccordionRoot>
    </>
  );
});
