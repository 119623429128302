import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLazyGetInfoPage } from '~api/content/contentQueries';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { NotFoundPage } from '~components/atoms/NotFoundPage';
import { Text } from '~components/atoms/Typography';
import { CHANNEL_TYPES, INFO_PAGE_SECTION_NAMES } from '~constants/common';
import { useTranslation } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { InviteFriendsSection } from '~pages/Info/InviteFriendsSection';
import { CallBackSection } from '~pages/Info/СallBackSection';

const SECTIONS_MAP: Partial<Record<INFO_PAGE_SECTION_NAMES, ReactNode>> = {
  [INFO_PAGE_SECTION_NAMES.CALLBACK]: <CallBackSection />,
  [INFO_PAGE_SECTION_NAMES.INVITE]: <InviteFriendsSection />,
};

interface InfoPageProps {
  pageName?: string;
  embedded?: boolean;
}

export const InfoPage = ({ pageName, embedded = false }: InfoPageProps) => {
  const { pathname } = useLocation();
  const { localized } = useTranslation();
  const name = pageName || pathname.split('/').pop();
  const {
    lazyGetInfoQuery,
    lazyGetInfoData,
    lazyGetInfoQueryError,
    lazyGetInfoQueryIsFetching,
  } = useLazyGetInfoPage();
  const htmlCode = lazyGetInfoData?.content || '';
  const { isMobileOrTablet } = useMedia();

  useEffect(() => {
    if (name) {
      lazyGetInfoQuery({
        name,
        channelType: isMobileOrTablet
          ? CHANNEL_TYPES.MOBILE
          : CHANNEL_TYPES.WEB,
      });
    }
  }, [name]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  if ((lazyGetInfoQueryError && !lazyGetInfoQueryIsFetching) || !name) {
    return <NotFoundPage />;
  }

  const renderTopSection = () =>
    SECTIONS_MAP[name as INFO_PAGE_SECTION_NAMES]
      ? SECTIONS_MAP[name as INFO_PAGE_SECTION_NAMES]
      : null;

  if (!lazyGetInfoData && lazyGetInfoQueryIsFetching) {
    return (
      <Box flexCol alignCenter gap={3} css={{ paddingTop: '32px' }}>
        <Loader css={{ color: '$grayMedium' }} />
        <Text color="grayMedium" level="sm-3" fontWeight="medium">
          {localized('betslipHistory.loading')}
        </Text>
      </Box>
    );
  }

  return (
    <Box
      flexCol
      alignCenter
      justifyContentBetween
      css={{
        maxWidth: '100vw',
        color: 'white',
        ...(!embedded && {
          backgroundColor: '$dialogBgColor',
          padding: '$4 $5',
        }),
      }}
    >
      <Box
        css={{
          width: '100%',
          '@lg_xl': {
            gap: '$3',
            flexDirection: 'row',
            alignItems: 'center',
            width: embedded ? '100%' : '1280px',
          },
          figure: {
            display: 'flex',
            justifyContent: 'center',
          },
          img: {
            width: '100%',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
          },
        }}
      >
        {renderTopSection()}

        <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
      </Box>
    </Box>
  );
};

export default InfoPage;
