import { prepareRespData } from '~components/molecules/LiveMenu/utils/eventsParsing';
import proto from '~proto/messages_pb';
import {
  getProtoCountsData,
  getProtoInPlayData,
  getProtoInPlayEventData,
  getProtoInPlayEventsByMarketData,
  getProtoMainMarketsData,
  getProtoPrematchData,
  getProtoPrematchEventData,
  getProtoPrematchEventsByLeagueData,
  getProtoPrematchSubMenuData,
  getProtoTopGamesData,
  getProtoTopTournamentsData,
  getProtoUpcomingData,
} from '~proto/protoMiddleware';
import {
  prepareTopTournamentsData,
  prepareUpcomingSportsOrTopGamesData,
} from '~proto/utils';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { isDebugMode } from '~utils/url';

const isJson =
  isDebugMode() || import.meta.env.VITE_WEBSOCKET_IS_JSON === 'true';

const prepareData = (type: unknown, data: any): unknown => {
  switch (type) {
    case ACTION_TYPE.GET_IN_PLAY:
      return isJson ? prepareRespData(data.events) : getProtoInPlayData(data);

    case ACTION_TYPE.GET_UPCOMING:
      return isJson
        ? prepareUpcomingSportsOrTopGamesData(
            data.sports,
            data.categories,
            true,
          )
        : getProtoUpcomingData(data);

    case ACTION_TYPE.GET_MAIN_MARKETS:
      return isJson
        ? {
            mainMarkets: data.sports,
            defaultSelectedMainMarkets: data.sports.reduce(
              (acc: unknown, sport: unknown) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                acc[sport.id] = sport.markets[0]?.id || null;

                return acc;
              },
              {},
            ),
          }
        : getProtoMainMarketsData(data);

    case ACTION_TYPE.GET_PREMATCH:
      return isJson ? data.sports : getProtoPrematchData(data);

    case ACTION_TYPE.GET_TOP_TOURNAMENTS:
      return isJson
        ? prepareTopTournamentsData(data.tournaments)
        : getProtoTopTournamentsData(data);

    case ACTION_TYPE.GET_TOP_GAMES:
      return isJson
        ? prepareUpcomingSportsOrTopGamesData(
            data.sports,
            data.categories,
            true,
          )
        : getProtoTopGamesData(data);

    case ACTION_TYPE.GET_PREMATCH_EVENT:
      return isJson ? data : getProtoPrematchEventData(data);

    case ACTION_TYPE.GET_IN_PLAY_EVENT:
      return isJson ? data : getProtoInPlayEventData(data);

    case ACTION_TYPE.GET_COUNTS:
      return isJson
        ? { inplay: data.inPlay, prematch: data.preMatch }
        : getProtoCountsData(data);

    case ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID:
      return isJson ? data.events : getProtoInPlayEventsByMarketData(data);

    case ACTION_TYPE.GET_PREMATCH_EVENTS_BY_LEAGUE:
      return isJson ? data : getProtoPrematchEventsByLeagueData(data);

    case ACTION_TYPE.GET_PREMATCH_SUB_MENU:
      return isJson ? data.categories : getProtoPrematchSubMenuData(data);
  }
};

const parseJsonMessage = (evt: MessageEvent) => {
  try {
    const { type, data } = JSON.parse(evt.data);

    return {
      type,
      data: prepareData(type, data),
    };
  } catch (error) {
    console.error('Error parsing JSON message:', error);

    return null;
  }
};

const parseProtoMessage = async (evt: MessageEvent) => {
  try {
    const arrayBuffer = await evt.data.arrayBuffer();
    const byteArray = new Uint8Array(arrayBuffer);
    const { type, data } =
      proto.UpdateMessage.deserializeBinary(byteArray).toObject();

    return {
      type,
      data: prepareData(type, data),
    };
  } catch (error) {
    console.error('Error parsing protobuf message:', error);

    return null;
  }
};

export const parseWebsocketMessage = async (evt: MessageEvent) => {
  if (isJson) {
    return parseJsonMessage(evt);
  } else {
    return parseProtoMessage(evt);
  }
};
