import { HubConnection } from '@microsoft/signalr';

type EventCallback = (data: unknown) => void;
type EventCallbacks = Record<string, EventCallback>;
type ListenerMap = Record<string, EventCallback[]>;
type EventCounterMap = Record<string, number>;

class SocketLiveEventService {
  private _socket: HubConnection | null;
  private _listeners: ListenerMap;
  private _eventCounters: EventCounterMap;

  constructor(
    socket: HubConnection | null,
    listeners?: ListenerMap,
    eventCounters?: EventCounterMap,
  ) {
    this._socket = socket;
    this._listeners = listeners || {};
    this._eventCounters = eventCounters || {};
  }

  get socket(): HubConnection | null {
    return this._socket;
  }

  get listeners(): ListenerMap {
    return this._listeners;
  }

  get eventCounters(): EventCounterMap {
    return this._eventCounters;
  }

  addListener(eventCallbacks: EventCallbacks): void {
    Object.entries(eventCallbacks).forEach(([event, callback]) => {
      if (!callback) return;

      if (!this._listeners[event]) {
        this._listeners[event] = [];
        this._eventCounters[event] = 0;

        if (this._socket) {
          this._socket.on(event, (data: unknown) => {
            const eventListeners = this._listeners[event];

            if (eventListeners) {
              eventListeners.forEach((cb) => cb(data));
            }
          });
        }
      }

      this._listeners[event]!.push(callback);
      this._eventCounters[event] += 1;
    });
  }

  removeListener(eventCallbacks: EventCallbacks): void {
    Object.entries(eventCallbacks).forEach(([event, callback]) => {
      if (!this._listeners[event]) return;

      this._listeners[event] =
        this._listeners[event]?.filter((cb) => cb !== callback) || [];
      this._eventCounters[event] -= 1;

      if (this._eventCounters[event] === 0) {
        if (this._socket) {
          this._socket.off(event);
        }

        delete this._listeners[event];
        delete this._eventCounters[event];
      }
    });
  }

  sendMessage(event: string | number, data?: any) {
    if (this._socket) {
      return data
        ? this._socket.invoke(String(event), data)
        : this._socket.invoke(String(event));
    }
  }
}

export default SocketLiveEventService;
