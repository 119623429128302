import React from 'react';
import { CSS } from 'stitches.config';

import { Tooltip } from '~components/atoms/Tooltip';
import { Text } from '~components/atoms/Typography';
import { useIsTextOverflowing } from '~hooks/useIsTextOverflowing';

interface OddsCellProps {
  text: string;
  css?: CSS;
}

export const TextWithTooltip = ({ text = '', css }: OddsCellProps) => {
  const { isOverflowing, spanRef } = useIsTextOverflowing();

  const TextContent = (
    <Text ellipsis ref={spanRef} as="span" level="xxxs-2" css={{ ...css }}>
      {text}
    </Text>
  );

  return isOverflowing ? (
    <Tooltip text={text}>{TextContent}</Tooltip>
  ) : (
    TextContent
  );
};
