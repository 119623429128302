import React from 'react';

import { IconButton } from '~components/atoms/Modal';
import { useRouterQuery } from '~hooks';
import { CloseIcon } from '~icons';

export const HeaderCloseButton = () => {
  const { navigateBackOrHome } = useRouterQuery();

  return (
    <IconButton
      relative
      aria-label="Close"
      onClick={navigateBackOrHome}
      style={{
        padding: '.25rem',
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};
