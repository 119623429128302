import { useEffect, useRef, useState } from 'react';

import { Sport } from '~api/category/types';
import { SportMainMarket } from '~api/market/types';
import {
  InPlayMenuSports,
  TopPrematchDateGroup,
  TopPrematchEvent,
  TopPrematchEvents,
  Tournament,
} from '~api/sportEvent/types';
import { processData } from '~components/molecules/PrematchEvents/helpers/extractData';
import { QUERY_PARAMS } from '~constants/common';
import { setCounts } from '~features/sportsMenu/sportsMenuSlice';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { parseWebsocketMessage } from '~proto/messageParser';
import { useAppDispatch, useAppSelector } from '~store';
import {
  addEventsWithMarkets,
  replaceEvents,
  rewriteEvent,
} from '~store/slices/eventsSlice';
import {
  resetAddedLeagueId,
  resetAddedSportId,
  setDefaultMainMarketsSelected,
  setIsEventLoaded,
  setIsLiveMenuLoaded,
  setIsLiveMenuLoading,
  setLiveEventData,
  setLiveMenuSports,
  setLoadingEventId,
  setLoadingSportId,
  setMarketGroups,
  setWidgetKey,
} from '~store/slices/liveMenuSlice';
import { setMainMarkets } from '~store/slices/mainMarketsSlice';
import {
  setActiveMainHighlightsSportId,
  setCountriesData,
  setIsAllLoaded,
  setIsLeagueLoading,
  setIsLoading,
  setLeagueEvents,
  setLeaguesData,
  setOpenedCountries,
  setOpenedSports,
  setSelectedLeagueData,
  setSports,
  setUpcomingEvents,
} from '~store/slices/prematchMenuSlice';
import { setPrimaryDataLoaded } from '~store/slices/signalRSocketsSlice';
import {
  setIsTopSportEventsLoaded,
  setIsTopTournamentsLoaded,
  setTopSportEvents,
  setTopTournaments,
} from '~store/slices/sportGroupsSlice';
import { SportEvent } from '~types/events';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { groupByMarketId } from '~utils/markets';
import { QueryParams } from '~utils/url';

const limit = 20;

const findLeftMenuOpenedLeague = (
  sports: Sport[],
  isNotInitial: boolean,
  sportId?: string,
  countryId?: string,
) => {
  const isSidebarCollapsedOnInitial = !isNotInitial && !sportId;

  let defaultSportId: string | null = null;

  const { id = '', countries } = sports.find((sport) => {
    const isSportWithCountries = !!sport.countries.length;

    if (isSportWithCountries && !defaultSportId) {
      defaultSportId = sport.id.toString();

      if (isSidebarCollapsedOnInitial) return true;
    }

    return sport.id.toString() === sportId;
  }) || { id: '', countries: [] };

  return {
    openedSportId: id.toString(),
    openedCountryId: (countryId || countries[0]?.id.toString()) ?? '',
    shouldUpdateParams: isSidebarCollapsedOnInitial,
    defaultSportId,
  };
};

export const useListenEventsLoadingSocket = () => {
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const { eventsSocket, eventSocketConnected, primaryDataLoaded } =
    useAppSelector((state) => state.signalRSockets);
  const { liveMenuSports } = useAppSelector((state) => state.liveMenu);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const mainMarketsRef = useRef(mainMarkets);
  const { upcomingEvents, countriesData, loadingCountryId, loadingSportId } =
    useAppSelector((state) => state.prematchMenu);
  const upcomingEventsRef = useRef(upcomingEvents);
  const queryParams = useQueryParams();
  const queryParamsRef = useRef(queryParams);
  const { sports } = useAppSelector((state) => state.prematchMenu);
  const sportsRef = useRef(sports);
  const loadingCountryIdRef = useRef(loadingCountryId);
  const loadingSportIdRef = useRef<number>(loadingSportId as number);
  const countriesDataRef = useRef(countriesData);
  const liveMenuSportsRef = useRef(liveMenuSports);

  const [isPrematchLoaded, setIsPrematchLoaded] = useState(false);
  const [isMarketLoaded, setIsMarketLoaded] = useState(false);
  const [isCountLoaded, setIsCountLoaded] = useState(false);

  // Track message processing
  const processingMessages = useRef(false);
  const messageQueue = useRef<unknown[]>([]);

  useEffect(() => {
    if (!primaryDataLoaded) {
      setIsCountLoaded(false);
      setIsMarketLoaded(false);
    }
  }, [primaryDataLoaded]);

  useEffect(() => {
    const primaryDataLoaded = isMarketLoaded && isCountLoaded;

    dispatch(setPrimaryDataLoaded(primaryDataLoaded));
  }, [isMarketLoaded, isCountLoaded]);

  useEffect(() => {
    liveMenuSportsRef.current = liveMenuSports;
  }, [liveMenuSports]);

  useEffect(() => {
    countriesDataRef.current = countriesData;
  }, [countriesData]);

  useEffect(() => {
    mainMarketsRef.current = mainMarkets;
  }, [mainMarkets]);

  useEffect(() => {
    queryParamsRef.current = queryParams;
  }, [queryParams]);

  useEffect(() => {
    sportsRef.current = sports;
  }, [sports]);

  useEffect(() => {
    upcomingEventsRef.current = upcomingEvents;
  }, [upcomingEvents]);

  useEffect(() => {
    loadingCountryIdRef.current = loadingCountryId;
  }, [loadingCountryId]);

  useEffect(() => {
    loadingSportIdRef.current = loadingSportId as number;
  }, [loadingSportId]);

  useEffect(() => {
    if (!eventsSocket || !eventSocketConnected) return;

    const handleSocketMessage = async (evt: MessageEvent) => {
      const res = await parseWebsocketMessage(evt);

      if (!res) throw Error('WebSocket response invalid');

      const { type, data } = res;

      // Each WS message has its own data, exacts types descriptions inside each component,
      // here it's "any" just to avoid type casting
      const parsedData = data as any;

      const { current: queryCurrent } = queryParamsRef;
      const {
        countryId: queryCountryId,
        group: queryGroup,
        sportId: querySportId,
        menu: queryMenu,
      } = queryCurrent;

      switch (type) {
        case ACTION_TYPE.GET_IN_PLAY:
          dispatch(setLiveMenuSports(parsedData as InPlayMenuSports));
          dispatch(setIsLiveMenuLoaded(true));

          break;
        case ACTION_TYPE.GET_UPCOMING:
          const upcomingSports = parsedData as TopPrematchEvents;

          const isAnyUpcomingLoaded = !!upcomingEventsRef.current?.length;

          if (isAnyUpcomingLoaded) {
            const { sportId: loadedSportId, dateGroups: dateGroupsLoaded } =
              upcomingSports.find(
                ({ dateGroups }) => dateGroups.length,
              ) as TopPrematchEvent;

            const previouslyLoadedData =
              upcomingEventsRef.current as TopPrematchEvents;

            const groupsAlreadyExists = !!previouslyLoadedData.find(
              ({ sportId }) => sportId === loadedSportId,
            )?.dateGroups.length;

            if (groupsAlreadyExists) {
              const totalEventsLoaded = dateGroupsLoaded.reduce(
                (acc, { matches }) => acc + matches.length,
                0,
              );

              if (totalEventsLoaded < limit) {
                dispatch(setIsAllLoaded(true));
              }

              const updatedData = previouslyLoadedData.map((sportData) => ({
                ...sportData,
                dateGroups: sportData.dateGroups.map((group) => ({
                  ...group,
                  matches: [...group.matches],
                })),
              }));

              const sportDataIndex = updatedData.findIndex(
                (sportData) => sportData.sportId === loadedSportId,
              );

              if (sportDataIndex !== -1) {
                const updatingData = updatedData[
                  sportDataIndex
                ] as TopPrematchEvent;

                dateGroupsLoaded.forEach(({ matches, date }) => {
                  const foundDateGroupIndex = updatingData.dateGroups.findIndex(
                    (group) => group.date === date,
                  );

                  if (foundDateGroupIndex !== -1) {
                    const foundDateGroup = {
                      ...updatingData.dateGroups[foundDateGroupIndex],
                    } as TopPrematchDateGroup;

                    foundDateGroup.matches = [
                      ...(foundDateGroup.matches as SportEvent[]),
                      ...matches,
                    ];
                    updatingData.dateGroups[foundDateGroupIndex] =
                      foundDateGroup;
                  } else {
                    updatingData.dateGroups.push({
                      date,
                      matches,
                    });
                  }
                });
              }

              dispatch(
                setActiveMainHighlightsSportId(loadedSportId.toString()),
              );
              dispatch(setUpcomingEvents(updatedData));

              return;
            } else {
              const preparedData = upcomingEventsRef.current?.map(
                ({ sportId: currentSportId, dateGroups, ...rest }) => {
                  if (currentSportId === loadedSportId) {
                    return {
                      ...rest,
                      sportId: currentSportId,
                      dateGroups: dateGroupsLoaded,
                    };
                  }

                  return {
                    ...rest,
                    sportId: currentSportId,
                    dateGroups,
                  };
                },
              );

              dispatch(setUpcomingEvents(preparedData as TopPrematchEvents));
            }
          } else {
            dispatch(setUpcomingEvents(upcomingSports));
          }

          break;
        case ACTION_TYPE.GET_MAIN_MARKETS:
          setIsMarketLoaded(true);
          const {
            mainMarkets,
            defaultSelectedMainMarkets,
          }: {
            mainMarkets: SportMainMarket[];
            defaultSelectedMainMarkets: Record<number, number>;
          } = parsedData;

          dispatch(setMainMarkets(mainMarkets));
          dispatch(setDefaultMainMarketsSelected(defaultSelectedMainMarkets));

          break;
        case ACTION_TYPE.GET_PREMATCH:
          const sportsMessage = parsedData as Sport[];

          dispatch(setSports(sportsMessage));

          const {
            openedCountryId,
            openedSportId,
            shouldUpdateParams,
            defaultSportId,
          } = findLeftMenuOpenedLeague(
            sportsMessage,
            isPrematchLoaded,
            querySportId,
            queryCountryId,
          );

          dispatch(setOpenedSports([openedSportId]));
          if (!queryGroup) {
            dispatch(setOpenedCountries([openedCountryId]));
          }

          if (shouldUpdateParams) {
            const newQueryParams: Partial<Record<QUERY_PARAMS, string>> = {};

            if (openedSportId) {
              newQueryParams[QUERY_PARAMS.SPORT_ID] = openedSportId;
            } else if (defaultSportId) {
              newQueryParams[QUERY_PARAMS.SPORT_ID] = defaultSportId;
            }

            if (openedCountryId) {
              newQueryParams[QUERY_PARAMS.COUNTRY_ID] = openedCountryId;
            }

            if (queryMenu) {
              newQueryParams[QUERY_PARAMS.MENU] = queryMenu;
            }

            updateQueryParams(
              newQueryParams as QueryParams<typeof QUERY_PARAMS>,
            );
          }

          setIsPrematchLoaded(true);

          dispatch(setIsLoading(false));
          break;
        case ACTION_TYPE.GET_TOP_TOURNAMENTS:
          const topTournaments = parsedData as Tournament[];

          dispatch(setTopTournaments(topTournaments));
          dispatch(setIsTopTournamentsLoaded(true));

          break;
        case ACTION_TYPE.GET_TOP_GAMES:
          const topGames = parsedData as TopPrematchEvents;

          dispatch(setTopSportEvents(topGames));
          dispatch(setIsTopSportEventsLoaded(true));

          break;
        case ACTION_TYPE.GET_PREMATCH_EVENT:
          const parsedPrematchEvent = parsedData;

          if (!parsedPrematchEvent) {
            const paramsCopy = { ...queryCurrent };

            delete paramsCopy.eventId;
            updateQueryParams(
              paramsCopy as QueryParams<typeof QUERY_PARAMS>,
              true,
            );

            return;
          }

          const { markets: prematchMarkets } =
            parsedPrematchEvent as SportEvent;

          dispatch(setWidgetKey(null));
          dispatch(setLiveEventData(parsedPrematchEvent));
          dispatch(setMarketGroups(groupByMarketId(prematchMarkets)));
          dispatch(rewriteEvent(parsedPrematchEvent));
          setTimeout(() => {
            dispatch(setLoadingEventId(null));
            dispatch(setIsEventLoaded(true));
          }, 100);
          break;
        case ACTION_TYPE.GET_IN_PLAY_EVENT:
          const parsedInPlayEvent = parsedData;

          if (!parsedInPlayEvent) {
            const paramsCopy = { ...queryCurrent };

            delete paramsCopy.eventId;
            updateQueryParams(
              paramsCopy as QueryParams<typeof QUERY_PARAMS>,
              true,
            );

            return;
          }

          const { markets } = parsedInPlayEvent as SportEvent;

          dispatch(setLiveEventData(parsedInPlayEvent));
          dispatch(setMarketGroups(groupByMarketId(markets)));
          setTimeout(() => {
            dispatch(setLoadingEventId(null));
            dispatch(setIsEventLoaded(true));
          }, 100);
          break;
        case ACTION_TYPE.GET_COUNTS:
          setIsCountLoaded(true);
          dispatch(setCounts(parsedData));
          break;
        case ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID:
          const inPlayEventsByMarketData = parsedData;

          const liveMenuSportsData = liveMenuSportsRef.current;

          if (liveMenuSportsData) {
            const resultInPlayMenu = liveMenuSportsData.map(
              ({ countries, ...rest1 }) => {
                return {
                  ...rest1,
                  countries: countries.map(({ leagues, ...rest2 }) => {
                    return {
                      ...rest2,
                      leagues: leagues.map(({ events, ...rest3 }) => {
                        return {
                          ...rest3,
                          events: events.map((event) => {
                            const updatedEvent = inPlayEventsByMarketData.find(
                              (evt: SportEvent) => evt.id === event.id,
                            );

                            if (updatedEvent) {
                              return {
                                ...event,
                                ...updatedEvent,
                              };
                            }

                            return event;
                          }),
                        };
                      }),
                    };
                  }),
                };
              },
            );

            dispatch(setLiveMenuSports(resultInPlayMenu));
          }

          dispatch(replaceEvents(inPlayEventsByMarketData));
          setTimeout(() => {
            dispatch(resetAddedLeagueId());
            dispatch(resetAddedSportId());
            dispatch(setLoadingSportId(null));
            dispatch(setIsLiveMenuLoading(false));
          }, 100);

          break;
        case ACTION_TYPE.GET_PREMATCH_EVENTS_BY_LEAGUE:
          const { data } = parsedData;

          if (data && mainMarketsRef.current.length && querySportId) {
            const { preparedData, allEvents, allMarkets } = processData(
              data,
              mainMarketsRef.current,
              parseInt(querySportId),
            );

            dispatch(
              addEventsWithMarkets({
                events: allEvents,
                markets: allMarkets,
              }),
            );

            dispatch(setSelectedLeagueData(data));
            dispatch(setLeagueEvents(preparedData));
          }

          setTimeout(() => {
            dispatch(setIsLeagueLoading(false));
          }, 300);

          break;
        case ACTION_TYPE.GET_PREMATCH_SUB_MENU:
          const countryParam = loadingCountryIdRef.current;
          const sportParam = loadingSportIdRef.current;
          const prematchData = sportsRef.current;

          const prematchSubMenuData = parsedData;

          if (!countryParam) {
            dispatch(
              setCountriesData({
                ...countriesDataRef.current,
                [sportParam]: prematchSubMenuData,
              }),
            );

            return;
          }

          if (sportParam && prematchData && countryParam) {
            const resSports = prematchData.map((sport) => {
              if (sport.id === sportParam) {
                return {
                  ...sport,
                  countries: sport.countries.map((country) => {
                    if (country.id === countryParam) {
                      return {
                        ...country,
                        leagues: prematchSubMenuData,
                      };
                    }

                    return country;
                  }),
                };
              }

              return sport;
            });

            dispatch(setSports(resSports));
            dispatch(
              setLeaguesData({
                id: countryParam.toString() || '',
                data: prematchSubMenuData,
              }),
            );
          }

          break;

        default:
          break;
      }
    };

    const processMessageQueue = async () => {
      if (processingMessages.current || !messageQueue.current.length) return;

      processingMessages.current = true;
      while (messageQueue.current.length) {
        const message = messageQueue.current.shift() as MessageEvent;

        if (message) await handleSocketMessage(message);
      }

      processingMessages.current = false;
    };

    eventsSocket.onmessage = (evt) => {
      messageQueue.current.push(evt);
      processMessageQueue();
    };

    return () => {
      if (eventsSocket) eventsSocket.onmessage = null;
    };
  }, [
    eventsSocket,
    eventSocketConnected,
    isPrematchLoaded,
    queryParamsRef.current,
  ]);
};
