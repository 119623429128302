import React, { useRef } from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { StyledBetslipFooterWrapper } from '~components/molecules/Betslip/components/BetslipStakes/styled.components';
import { useRouterQuery } from '~hooks';
import { useComponentObserver } from '~hooks/useComponentObserver';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { setBetslipFooterHeight } from '~store/slices/userUISlice';

import { SPORT_BETSLIP_TYPE_OPTIONS } from '../../constants';

import { BetAdjustmentButtons } from './BetAdjustmentButtons';
import { BetslipAggregatedValue } from './BetslipAggregatedValue';
import { BetslipErrors } from './BetslipErrors';
import { BetslipFooterInactiveButton } from './BetslipFooterInactiveButton';
import { BetslipSubmitButton } from './BetslipSubmitButton';

interface BetslipFooterProps {
  isStatic?: boolean;
  quickBet?: boolean;
  isInfoMessage?: boolean;
  handleErrorMessageCloseClick: () => void;
  betslipHasElements: boolean;
}

export const BetslipFooter = ({
  isStatic,
  quickBet = false,
  isInfoMessage = false,
  handleErrorMessageCloseClick,
  betslipHasElements,
}: BetslipFooterProps) => {
  const { navigateBackOrHome } = useRouterQuery();
  const { isMobileOrTablet } = useMedia();
  const { stakeType, events } = useAppSelector((state) => state.betslip);
  const isMultipleOrSystemBet =
    stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE ||
    stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const componentRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useComponentObserver(componentRef, (height) => {
    dispatch(setBetslipFooterHeight(height));
  });

  if (
    (!isInfoMessage && quickBet && !events.length) ||
    (isInfoMessage && !isMobileOrTablet)
  ) {
    return null;
  }

  return (
    <StyledBetslipFooterWrapper ref={componentRef} static={isStatic}>
      {isInfoMessage ? (
        <BetslipFooterInactiveButton
          isQuickBet={quickBet}
          onClick={handleErrorMessageCloseClick}
        />
      ) : (
        <Box flexCol>
          {isMultipleOrSystemBet && !!events.length && (
            <>
              <BetslipAggregatedValue />
            </>
          )}
          {isMobileOrTablet && <Separator verticalSpace={0} shrinkOut={0} />}
          <BetslipErrors isQuickBet={quickBet} />
          {betslipHasElements && (
            <>
              <Separator
                verticalSpace={0}
                shrinkOut={0}
                css={{ backgroundColor: '$betslipFooterSeparatorBgColor' }}
              />
              <BetAdjustmentButtons />
              <Separator
                verticalSpace={0}
                shrinkOut={0}
                css={{ backgroundColor: '$betslipFooterSeparatorBgColor' }}
              />
            </>
          )}
          {betslipHasElements ? (
            <BetslipSubmitButton quickBet={quickBet} />
          ) : (
            isMobileOrTablet && (
              <BetslipFooterInactiveButton
                isQuickBet={quickBet}
                onClick={navigateBackOrHome}
                text={'buttons.startBetting'}
              />
            )
          )}
        </Box>
      )}
    </StyledBetslipFooterWrapper>
  );
};
