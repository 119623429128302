import React from 'react';

import { Box } from '~components/atoms/Box';
import { getSportIcon } from '~utils/getSportIcon';
import { SERVER } from '~utils/sportHelpers';

import { ScoreColumn } from './utils/getScores';
import {
  BreakdownScoreCardColumn,
  BreakdownScoreCardColumnRowAway,
  BreakdownScoreCardColumnRowHome,
  BreakdownScoreCardTitle,
  ServerIconWrapper,
  StyledBreakDownScoreCard,
} from './styled.components';

interface BreakdownScoreCardProps {
  scores: ScoreColumn[];
  sportId: number;
  server?: SERVER | null;
}

export const BreakdownScoreCard = ({
  scores,
  sportId,
  server,
}: BreakdownScoreCardProps) => {
  if (!scores) return null;

  return (
    <Box flexRow justifyCenter css={{ mt: '$4' }}>
      <StyledBreakDownScoreCard>
        {scores.map(({ title, home, away }, index) => (
          <BreakdownScoreCardColumn key={index}>
            <BreakdownScoreCardTitle>{title}</BreakdownScoreCardTitle>
            <BreakdownScoreCardColumnRowHome>
              <ServerIconWrapper as="span">
                {index === 0 &&
                  server === SERVER.HOME &&
                  getSportIcon(sportId, { width: 12, height: 12 })}
              </ServerIconWrapper>
              {home}
            </BreakdownScoreCardColumnRowHome>
            <BreakdownScoreCardColumnRowAway>
              <ServerIconWrapper as="span">
                {index === 0 &&
                  server === SERVER.AWAY &&
                  getSportIcon(sportId, { width: 12, height: 12 })}
              </ServerIconWrapper>
              {away}
            </BreakdownScoreCardColumnRowAway>
          </BreakdownScoreCardColumn>
        ))}
      </StyledBreakDownScoreCard>
    </Box>
  );
};
