import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import { SOCKET_TYPE } from '~socket-service/constants';
import { useAppSelector } from '~store';

import SocketEventsService from '../SocketEventsService';
import SocketLiveEventService from '../SocketLiveEventService';

type EventCallback = (data: any) => void;
type EventCallbacks = Record<string, EventCallback>;

const isWsSocket = (socket: unknown): socket is WebSocket => {
  return socket instanceof WebSocket;
};

const isHubConnection = (socket: unknown): socket is HubConnection => {
  return socket instanceof HubConnection;
};

const useSocket = (socketType: SOCKET_TYPE) => {
  const { socketEventsService, socketLiveEventsService } = useAppSelector(
    (state) => state.signalRSockets,
  );

  const [socketService, setSocketService] = useState<
    SocketLiveEventService | SocketEventsService | null
  >();

  useEffect(() => {
    setSocketService(
      socketType === SOCKET_TYPE.WEB
        ? socketEventsService
        : socketLiveEventsService,
    );
  }, [socketEventsService, socketLiveEventsService]);

  const isSocketReady = () => {
    if (!socketService) return false;

    if (isHubConnection(socketService.socket)) {
      return socketService.socket.state === 'Connected';
    }

    if (isWsSocket(socketService.socket)) {
      return socketService.socket.readyState === WebSocket.OPEN;
    }

    return false;
  };

  const listening = (eventCallbacks: EventCallbacks) => {
    socketService && socketService.addListener(eventCallbacks);

    return () => {
      socketService && socketService.removeListener(eventCallbacks);
    };
  };

  const sendMessage = (event: string | number, data?: unknown) => {
    socketService && socketService.sendMessage(event, data);
  };

  return { listening, sendMessage, isSocketReady: isSocketReady() };
};

export default useSocket;
