import React from 'react';

import { Tooltip } from '~components/atoms/Tooltip';
import { Text } from '~components/atoms/Typography';
import { SPORT_BETSLIP_TYPE_CODES } from '~components/molecules/Betslip/constants';
import { useIsTextOverflowing } from '~hooks/useIsTextOverflowing';

interface OddsCellProps {
  type: SPORT_BETSLIP_TYPE_CODES;
  totalOdd: number;
}

export const OddsCell = ({ type, totalOdd }: OddsCellProps) => {
  const { isOverflowing, spanRef } = useIsTextOverflowing();
  const displayText =
    type === SPORT_BETSLIP_TYPE_CODES.SYSTEM ? '-' : totalOdd.toString();

  const TextContent = (
    <Text ellipsis ref={spanRef} as="span" level="12-20">
      {displayText}
    </Text>
  );

  return isOverflowing ? (
    <Tooltip text={displayText}>{TextContent}</Tooltip>
  ) : (
    TextContent
  );
};
