import React, { memo } from 'react';

import { SportEventItem } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { OddButtonGroup } from '~components/molecules/LiveMenu/components/OddButtonGroup';
import { useTwoTeamScore } from '~hooks/useTwoTeamScore';
import { Market } from '~types/events';
import { ExtraData } from '~types/sportExtraData';

import { LiveEventDetails, useLiveEventDetails } from '../liveEventDetails';
import { TwoTeamScore } from '../liveEventScores';

export interface FallbackContentProps {
  isMobile: boolean;
  isLoading: boolean;
  sportId: number;
  extraData: unknown;
  eventName: string | undefined;
  startDate: string | undefined;
  marketsCount: number | undefined;
  market: Market | undefined;
  eventData: SportEventItem;
}

export const FallbackContent = memo(
  ({
    isMobile,
    isLoading,
    sportId,
    extraData,
    eventName,
    marketsCount,
    startDate,
    market,
    eventData,
  }: FallbackContentProps) => {
    const { homeTeamScore, awayTeamScore } = useTwoTeamScore(
      extraData as ExtraData,
    );
    const { formattedMarketsCount, formattedStartDate, formattedMatchStatus } =
      useLiveEventDetails(marketsCount, startDate, extraData as never);

    return (
      <Box flexCol gap={2}>
        <Box
          gap={3}
          fullWidth
          css={{
            display: 'grid',
            gridTemplateColumns: 'auto min-content',
          }}
        >
          <TwoTeamScore
            eventName={eventName}
            homeScore={homeTeamScore}
            awayScore={awayTeamScore}
          />
          {isMobile && (
            <Box>
              <OddButtonGroup
                eventData={eventData}
                fullHeight
                sportId={sportId}
                isLoading={isLoading}
                market={market}
              />
            </Box>
          )}
        </Box>
        <LiveEventDetails
          eventData={eventData}
          isMobile={isMobile}
          marketsCount={formattedMarketsCount}
          startDate={formattedStartDate}
          matchStatus={formattedMatchStatus}
        />
      </Box>
    );
  },
);
