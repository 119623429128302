import { useCallback, useEffect } from 'react';
import { useTranslation as useI18nTranslation } from 'react-i18next';
import { TOptions } from 'i18next';

import { LANGUAGE_TYPES, languagesCode } from '~constants/language';
import { useAppSelector } from '~store';
import { selectLanguage } from '~store/slices/websiteSettings';

export const useTranslation = () => {
  const language = useAppSelector(selectLanguage);
  const { i18n, t } = useI18nTranslation();

  useEffect(() => {
    const langCode =
      languagesCode[language] || languagesCode[LANGUAGE_TYPES.English];

    if (language) {
      i18n.changeLanguage(langCode);
    }
  }, [language]);

  const localized = useCallback(
    (key: string, args?: object) => t(key, args as TOptions),
    [],
  );

  const localizedError = useCallback(
    (key: string, args?: object) => t(key, { ns: 'error', ...args }),
    [],
  );

  return { t, localized, localizedError };
};
