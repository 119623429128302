import * as RadioGroupUI from '@radix-ui/react-radio-group';
import { styled } from 'stitches.config';

export const RadioGroupRoot = RadioGroupUI.Root;

export const StyledRadioGroupItem = styled(RadioGroupUI.Item, {
  all: 'unset',
  backgroundColor: 'transparent',
  position: 'relative',
  width: '$5',
  height: '$5',
  borderRadius: '$full',
  border: '1px solid $white',
  cursor: 'pointer',
  '&[data-state="checked"]': {
    backgroundColor: '$white',
    border: '1px solid $white !important',
  },
  '&[data-disabled]': {
    opacity: 0.4,
    cursor: 'default',
  },
});

export const StyledRadioGroupItemIndicator = styled(RadioGroupUI.Indicator, {
  width: '$5',
  height: '$5',
  position: 'absolute',
  left: 0,
  top: 0,
  borderRadius: '$round',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:after': {
    borderRadius: '$round',
    content: '""',
    width: '$2',
    height: '$2',
    backgroundColor: '$greenBlue',
  },
});
