export enum SIGNUP_FORM_FIELDS {
  USERNAME_FIELD = 'usernameField',
  PHONE_NUMBER_FIELD = 'phoneNumberField',
  PASSWORD_FIELD = 'passwordField',
  PROMOTION_CODE_FIELD = 'promotionCodeField',
  AGREE_TO_TERMS_FIELD = 'agreeToTermsField',
  NUMBER_CODE_FIELD = 'numberCodeField',
}

export type SignupFormInputs = {
  usernameField: string;
  phoneNumberField: string;
  passwordField: string;
  promotionCodeField: string;
  agreeToTermsField: boolean;
  numberCodeField: string;
};

export enum SIGNIN_FORM_FIELDS {
  USERNAME_FIELD = 'usernameField',
  EMAIL_FIELD = 'emailField',
  PHONE_NUMBER_FIELD = 'phoneNumberField',
  PASSWORD_FIELD = 'passwordField',
}

export type SigninFormInputs = {
  usernameField: string;
  passwordField: string;
  emailField: string;
  phoneNumberField: string;
};

export enum RESET_PASSWORD_FORM_FIELDS {
  PHONE_NUMBER_FIELD = 'phoneNumberField',
  NUMBER_CODE_FIELD = 'numberCodeField',
  NEW_PASSWORD_FIELD = 'newPasswordField',
}

export type ResetPasswordFormInputs = {
  phoneNumberField: string;
  numberCodeField: string;
  newPasswordField: string;
};

export enum SUPPORTED_COUNTRY_CODE {
  NGN = 'NG',
  UGX = 'UG',
  MZ = 'MZ',
  UA = 'UA',
  TZ = 'TZ',
  BR = 'BR',
}
