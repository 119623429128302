import { createSelector } from '@reduxjs/toolkit';

import { BalanceChange } from '~api/betslip/types';
import {
  BETSLIP_VALUES_MAP_OPTIONS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '~components/molecules/Betslip/constants';
import { RootState } from '~store';
import { Market } from '~types/events';

/*  Getters */
export const getBetslip = (state: RootState) => state.betslip;
const getEvents = (state: RootState) => state.betslip.events;
const getEventsData = (state: RootState) => state.betslip.eventsData;
const getStakePossibleWinLoadingMap = (state: RootState) =>
  state.betslip.stakePossibleWinLoadingMap;

export const getJackpotsBetsHistory = (state: RootState) =>
  state.betslip.jackpotsBetsHistory;
const getBalanceChangesMap = (state: RootState) =>
  state.betslip.balanceChangesMap;

/* Selectors */
export const selectJackpotHistoryById = (id?: string) =>
  createSelector(getJackpotsBetsHistory, (jackpotBetsHistory) => {
    return jackpotBetsHistory.find((jackpot) => jackpot.id === id);
  });

export const selectAllMarkets = createSelector(
  [getEventsData],
  (eventsData) => {
    return eventsData.flatMap((event) => event.markets);
  },
);

export const selectActiveButtons = (market?: Market) =>
  createSelector([getEvents], (events) => {
    return events
      .filter((event) => event.marketId === market?.id)
      .map((event) => event.selectionId);
  });

export const selectIsSelectionInBetslip = (selectionId: string) =>
  createSelector([getEvents], (events) => {
    return events.some((event) => event.selectionId === selectionId);
  });

export const selectIsEmptyBetslip = createSelector([getEvents], (events) => {
  return events.length === 0;
});

export const selectIsSingleBetslip = createSelector([getEvents], (events) => {
  return events.length === 1;
});

export const selectBalanceChangeById = (id?: string) =>
  createSelector(
    [getBalanceChangesMap],
    (balanceChangesMap): BalanceChange | undefined => {
      if (!id) return;

      return balanceChangesMap?.[id];
    },
  );

export const selectPossibleWinLoadingById = (id: string) =>
  createSelector(
    [getStakePossibleWinLoadingMap],
    (possibleWinLoadingMap): boolean | undefined =>
      possibleWinLoadingMap[id] ||
      possibleWinLoadingMap[BETSLIP_VALUES_MAP_OPTIONS.ALL],
  );

export const selectIsSingleBetslipType = createSelector(
  [getBetslip],
  (betslip) => betslip.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE,
);
