import React, { memo } from 'react';

import { ToggleEventMarkets } from '~components/atoms/ToggleMarket/ToggleEventMarkets';
import { useAppDispatch, useAppSelector } from '~store';
import { setMarketsOfFavoriteEventsSelected } from '~store/slices/liveMenuSlice';

interface ToggleFavoriteEventSportMarketProps {
  sportId: number;
  noMargin?: boolean;
}

export const ToggleFavoriteEventSportMarket = memo(
  ({ sportId, noMargin }: ToggleFavoriteEventSportMarketProps) => {
    const dispatch = useAppDispatch();
    const { marketsOfFavoriteEventsSelected } = useAppSelector(
      (state) => state.liveMenu,
    );
    const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
    const { markets = [] } = mainMarkets.find(({ id }) => id === sportId) || {};

    const handleValueChange = (marketId: string) => {
      dispatch(
        setMarketsOfFavoriteEventsSelected({
          sportId,
          marketId: parseInt(marketId),
        }),
      );
    };

    if (!(markets?.length > 1)) return null;

    return (
      <ToggleEventMarkets
        sportId={sportId}
        noMargin={noMargin}
        variant="lightMarket"
        mainMarketsSelected={marketsOfFavoriteEventsSelected}
        handleValueChange={handleValueChange}
      />
    );
  },
);
