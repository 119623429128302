import React from 'react';

import { Bonus } from '~api/bonus/types';
import { Box } from '~components/atoms/Box';
import { ProgressBar } from '~components/atoms/ProgressBar';
import { Text } from '~components/atoms/Typography';
import { BetslipBalancesPopover } from '~components/molecules/Bonuses/components/ActiveBonusCard/ActiveBonusBetslipCodes';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import { CopyToClipboardIcon, GreenCheckIcon } from '~icons';

import { ActiveBonusExpiration } from './ActiveBonusExpiration';
import {
  StyledActiveBonusTotal,
  StyledActiveBonusWon,
  StyledActiveBonusWrapper,
} from './styled.components';
import { useActiveBonusCard } from './useActiveBonusCard';

export const ActiveBonusCard = ({ bonus }: { bonus: Bonus }) => {
  const {
    days,
    givenDate,
    hours,
    isMobileOrTablet,
    maxWinText,
    minutes,
    progress,
    remainingAmountText,
    title,
    betSlipCodes,
    localized,
  } = useActiveBonusCard({ bonus });
  const { isCopied, handleCopy } = useCopyToClipboard();

  return (
    <StyledActiveBonusWrapper flexCol gap={3}>
      <Text
        color="whiteDark"
        level={isMobileOrTablet ? '11-20' : '12-20'}
        fontWeight="medium"
        textTransform="capitalize"
      >
        {givenDate}
      </Text>
      <Box flexCol alignCenter gap={3}>
        {betSlipCodes && betSlipCodes.length ? (
          <Box
            fullWidth
            flexRow
            justifyCenter
            css={{
              p: '6px',
              backgroundColor: '$grayDark',
              borderRadius: '$8',
            }}
          >
            <Text
              level={isMobileOrTablet ? '18-40' : '20-44'}
              fontWeight="bold"
              textTransform="uppercase"
            >
              {title}
            </Text>
          </Box>
        ) : (
          <Text
            level={isMobileOrTablet ? '18-40' : '20-44'}
            fontWeight="bold"
            textTransform="uppercase"
          >
            {title}
          </Text>
        )}
        {betSlipCodes && betSlipCodes.length ? (
          <Box flexRow gap={2} alignCenter>
            <Text level="12-20">
              {localized('bonuses.betId')} {betSlipCodes[0]}
            </Text>
            {betSlipCodes.length > 1 ? (
              <BetslipBalancesPopover betSlipCodes={betSlipCodes} />
            ) : (
              <Box
                css={{
                  display: 'flex',
                  cursor: 'pointer',
                  color: '$grayMedium',
                  width: '$3',
                }}
                onClick={() => {
                  handleCopy((betSlipCodes[0] || 0).toString());
                }}
              >
                {isCopied ? <GreenCheckIcon /> : <CopyToClipboardIcon />}
              </Box>
            )}
          </Box>
        ) : null}
        <StyledActiveBonusTotal flexRow alignCenter justifyContentBetween>
          <ProgressBar progress={progress} />
          <Box flexRow css={{ flexShrink: 0 }}>
            <Text color="white" level="14-24" fontWeight="medium">
              {remainingAmountText}
            </Text>
          </Box>
        </StyledActiveBonusTotal>
        <StyledActiveBonusWon flexRow alignCenter justifyContentBetween>
          <Text level="14-24" fontWeight="medium" color="whiteYellow">
            {localized('bonuses.won')}
          </Text>
          <Text level="14-24" fontWeight="medium" color="whiteYellow">
            {maxWinText}
          </Text>
        </StyledActiveBonusWon>
        <ActiveBonusExpiration days={days} hours={hours} minutes={minutes} />
      </Box>
    </StyledActiveBonusWrapper>
  );
};
