import { CountryName } from '~constants/flag';

export enum LANGUAGE_TYPES {
  English = 1,
  French = 2,
  Spanish = 3,
  Portuguese = 4,
  Hindi = 5,
  Farsi = 6,
}

export const languagesCode: Record<LANGUAGE_TYPES, string> = {
  [LANGUAGE_TYPES.English]: 'en',
  [LANGUAGE_TYPES.French]: 'fr',
  [LANGUAGE_TYPES.Spanish]: 'es',
  [LANGUAGE_TYPES.Portuguese]: 'pt',
  [LANGUAGE_TYPES.Hindi]: 'hi',
  [LANGUAGE_TYPES.Farsi]: 'fa',
};

export const LANGUAGES: {
  value: LANGUAGE_TYPES;
  label: string;
  flag: CountryName;
  title: string;
}[] = [
  {
    value: LANGUAGE_TYPES.English,
    label: 'English',
    flag: 'England',
    title: languagesCode[LANGUAGE_TYPES.English],
  },
  {
    value: LANGUAGE_TYPES.French,
    label: 'French',
    flag: 'France',
    title: languagesCode[LANGUAGE_TYPES.French],
  },
  {
    value: LANGUAGE_TYPES.Spanish,
    label: 'Spanish',
    flag: 'Spain',
    title: languagesCode[LANGUAGE_TYPES.Spanish],
  },
  {
    value: LANGUAGE_TYPES.Portuguese,
    label: 'Portuguese',
    flag: 'Portugal',
    title: languagesCode[LANGUAGE_TYPES.Portuguese],
  },
  {
    value: LANGUAGE_TYPES.Hindi,
    label: 'Hindi',
    flag: 'India',
    title: languagesCode[LANGUAGE_TYPES.Hindi],
  },
  {
    value: LANGUAGE_TYPES.Farsi,
    label: 'Farsi',
    flag: 'Iran',
    title: languagesCode[LANGUAGE_TYPES.Farsi],
  },
];
