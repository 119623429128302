import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledFooterCloseModalWrapper = styled(Box, {
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$blackOpacity7',
  borderTop: '1px solid $grayDarkMain2',
  p: '$3',
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
});
