import { useEffect, useRef } from 'react';

import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsLeagueLoading,
  setSelectedLeagueId,
} from '~store/slices/prematchMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

export const useLeagueEventsWithMarkets = () => {
  const { leagueId: activeLeague } = useQueryParams();
  const {
    leagueEvents,
    selectedLeagueData,
    dateToFilter,
    isLeagueLoading: isLoading,
  } = useAppSelector((state) => state.prematchMenu);
  const { isPrimaryDataLoaded, sendWsMessage } = useEventsLoadingSocket();
  const dispatch = useAppDispatch();
  const prevDateTo = useRef('');
  const language = useAppSelector(selectLanguage);

  useEffect(() => {
    if (!isPrimaryDataLoaded) return;
    if (prevDateTo.current !== dateToFilter && activeLeague) {
      dispatch(setIsLeagueLoading(true));
      prevDateTo.current = dateToFilter || '';
      dispatch(setSelectedLeagueId(activeLeague));
      sendWsMessage(ACTION_TYPE.GET_PREMATCH_EVENTS_BY_LEAGUE, {
        LeagueId: +activeLeague,
        DateTo: dateToFilter,
        Language: language,
      });
    }
  }, [activeLeague, dateToFilter, language, isPrimaryDataLoaded]);

  return {
    leagueEventsData: leagueEvents,
    prematchEventsByLeagueData: selectedLeagueData,
    isLoading,
  };
};
