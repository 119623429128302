import React from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { IconButton } from '~components/atoms/Modal';
import { Text } from '~components/atoms/Typography';
import { useRouterQuery, useTranslation } from '~hooks';
import { ArrowLeftIcon, CloseIcon } from '~icons';
import { zIndex } from '~utils/zIndex';

const StyledHeader = styled('header', {
  position: 'fixed',
  height: '60px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '$dialogBgColor',
  padding: '0 16px',
  color: '$white',
  borderBottom: '1px solid $headerBottomBorder',
  zIndex: zIndex.composition.modal.fixed,
});

export const BackButtonStyled = styled(Box, {
  color: '$grayMedium',
  width: '80px',
  display: 'flex',
  alignItems: 'center',
  '&:hover *': {
    color: '$white',
  },
  cursor: 'pointer',
});

export type HeaderModalProps = {
  title: string;
};

export const HeaderModalComponent = ({ title }: HeaderModalProps) => {
  const { navigateBackOrHome, navigateHome } = useRouterQuery();
  const { localized } = useTranslation();

  return (
    <StyledHeader>
      <BackButtonStyled onClick={navigateBackOrHome}>
        <ArrowLeftIcon />
        <Text
          textTransform="uppercase"
          level="14-20"
          css={{
            margin: '0px 8px',
            color: '$grayMedium',
          }}
        >
          {localized('buttons.back')}
        </Text>
      </BackButtonStyled>
      <Box
        css={{
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        <div style={{ fontSize: '14px' }}>{localized(title)}</div>
      </Box>
      <Box style={{ width: '80px', textAlign: 'end' }}>
        <IconButton aria-label="Close" style={{ position: 'static' }}>
          <CloseIcon
            style={{ width: '16px', height: '16px' }}
            onClick={navigateHome}
          />
        </IconButton>
      </Box>
    </StyledHeader>
  );
};
