import React from 'react';
import { Cell, Row } from 'react-table';
import { COLOR_KEYS } from 'stitches.config';

import { Transaction } from '~api/transaction/types';
import { ClockIcon, LostStatusIcon, StatusWinIcon } from '~icons';

export enum TRANSACTION_TYPE {
  BET = 1,
  WIN = 2,
  VOID = 3,
  BET_REVERSE = 4,
  WIN_REVERSE = 5,
  DEPOSIT = 6,
  WITHDRAWAL = 7,
  BALANCE_ADJUSTMENT = 8,
  BONUS = 9,
  LOST = 10,
  WINBOOST = 11,
  CASHOUT = 12,
  STAKE_TAX = 19,
  INCOME_TAX = 20,
  STAKE_TAX_REVERSE = 21,
}

export enum TRANSACTION_STATUS {
  SUCCESS = 1,
  PENDING = 2,
  FAILED = 3,
  IN_REVIEW = 4,
  REJECTED = 6,
}

export const transactionsTranslationsMap = {
  [TRANSACTION_TYPE.BET]: 'transactionHistory.transactionType.bet',
  [TRANSACTION_TYPE.WIN]: 'transactionHistory.transactionType.win',
  [TRANSACTION_TYPE.VOID]: 'transactionHistory.transactionType.void',
  [TRANSACTION_TYPE.BET_REVERSE]:
    'transactionHistory.transactionType.betReverse',
  [TRANSACTION_TYPE.WIN_REVERSE]:
    'transactionHistory.transactionType.winReverse',
  [TRANSACTION_TYPE.DEPOSIT]: 'transactionHistory.transactionType.deposit',
  [TRANSACTION_TYPE.WITHDRAWAL]: 'transactionHistory.transactionType.withdraw',
  [TRANSACTION_TYPE.BALANCE_ADJUSTMENT]:
    'transactionHistory.transactionType.balanceAdjustment',
  [TRANSACTION_TYPE.BONUS]: 'transactionHistory.transactionType.bonus',
  [TRANSACTION_TYPE.LOST]: 'transactionHistory.transactionType.lost',
  [TRANSACTION_TYPE.WINBOOST]: 'transactionHistory.transactionType.winboost',
  [TRANSACTION_TYPE.CASHOUT]: 'transactionHistory.transactionType.cashout',
  [TRANSACTION_TYPE.STAKE_TAX]: 'transactionHistory.transactionType.stakeTax',
  [TRANSACTION_TYPE.INCOME_TAX]: 'transactionHistory.transactionType.incomeTax',
  [TRANSACTION_TYPE.STAKE_TAX_REVERSE]:
    'transactionHistory.transactionType.stakeTaxReverse',
};

export const transactionsTypeColorsMap = {
  [TRANSACTION_TYPE.BET]: 'green',
  [TRANSACTION_TYPE.WIN]: 'green',
  [TRANSACTION_TYPE.VOID]: 'grayMedium',
  [TRANSACTION_TYPE.BET_REVERSE]: 'yellowlight',
  [TRANSACTION_TYPE.WIN_REVERSE]: 'yellowlight',
  [TRANSACTION_TYPE.DEPOSIT]: 'white',
  [TRANSACTION_TYPE.WITHDRAWAL]: 'white',
  [TRANSACTION_TYPE.BALANCE_ADJUSTMENT]: 'yellowlight',
  [TRANSACTION_TYPE.BONUS]: 'green',
  [TRANSACTION_TYPE.LOST]: 'red',
  [TRANSACTION_TYPE.WINBOOST]: 'green',
  [TRANSACTION_TYPE.CASHOUT]: 'green',
  [TRANSACTION_TYPE.STAKE_TAX]: 'white',
  [TRANSACTION_TYPE.INCOME_TAX]: 'white',
  [TRANSACTION_TYPE.STAKE_TAX_REVERSE]: 'yellowlight',
};

export const statusTranslationsMap = {
  [TRANSACTION_STATUS.SUCCESS]: 'transactionHistory.transactionStatus.success',
  [TRANSACTION_STATUS.PENDING]: 'transactionHistory.transactionStatus.pending',
  [TRANSACTION_STATUS.FAILED]: 'transactionHistory.transactionStatus.failed',
  [TRANSACTION_STATUS.IN_REVIEW]:
    'transactionHistory.transactionStatus.inReview',
  [TRANSACTION_STATUS.REJECTED]:
    'transactionHistory.transactionStatus.rejected',
};

export const transactionTypeOptions = [
  'ALL',
  TRANSACTION_TYPE.DEPOSIT,
  TRANSACTION_TYPE.WITHDRAWAL,
];

export const transactionStatusOptions = [
  'ALL',
  TRANSACTION_STATUS.SUCCESS,
  TRANSACTION_STATUS.PENDING,
  TRANSACTION_STATUS.FAILED,
  TRANSACTION_STATUS.IN_REVIEW,
];

export interface TransactionHistoryCellProps {
  cell: Cell<Transaction>;
  row: Row<Transaction>;
}

export const TRANSACTION_STATUS_CODE_COLORS: Record<
  TRANSACTION_STATUS,
  COLOR_KEYS
> = {
  [TRANSACTION_STATUS.FAILED]: 'red',
  [TRANSACTION_STATUS.IN_REVIEW]: 'yellowLight',
  [TRANSACTION_STATUS.PENDING]: 'yellowLight',
  [TRANSACTION_STATUS.SUCCESS]: 'greenLight',
  [TRANSACTION_STATUS.REJECTED]: 'red',
};

export const TRANSACTION_STATUS_ICONS: Record<
  TRANSACTION_STATUS,
  React.ElementType
> = {
  [TRANSACTION_STATUS.FAILED]: LostStatusIcon,
  [TRANSACTION_STATUS.IN_REVIEW]: ClockIcon,
  [TRANSACTION_STATUS.PENDING]: ClockIcon,
  [TRANSACTION_STATUS.SUCCESS]: StatusWinIcon,
  [TRANSACTION_STATUS.REJECTED]: LostStatusIcon,
};
