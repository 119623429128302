import { createElement } from 'react';

import { sportIconMap, sportIdToSportNameMap } from '~constants/sport';
import { SVGElementProps } from '~types/general';

export const getSportIcon = (sportId: number, props?: SVGElementProps) => {
  const sportName = sportIdToSportNameMap[sportId];

  if (!sportName) return null;
  const sportIcon = sportIconMap[sportName];

  return sportIcon
    ? createElement(sportIcon.icon, { ...props, color: sportIcon.color })
    : null;
};
