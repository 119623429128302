import { useRef } from 'react';

import { UserProfilePayload } from '~api/user/types';
import { useUpdateUserProfile } from '~api/user/userMutations';
import { useDebouncedEffect } from '~hooks/useDebouncedEffect';
import { useAppSelector } from '~store';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

export const useUpdateWebsiteSettings = () => {
  const { updateUserProfileMutation } = useUpdateUserProfile();

  const { isUserLoggedIn, profile } = useAppSelector(
    (state) => state.userState,
  );

  const profilePrevRef = useRef(profile);

  useDebouncedEffect(
    () => {
      if (
        !isUserLoggedIn ||
        !profile ||
        areTwoObjectsEqual(profile, profilePrevRef.current)
      ) {
        return;
      }

      profilePrevRef.current = profile;

      updateUserProfileMutation(profile as UserProfilePayload);
    },
    [profile],
    300,
  );
};
