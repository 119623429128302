import { MARKET_STATUS } from '~constants/common';
import { Market } from '~types/events';

export function groupByMarketId(data: (Market | undefined)[]) {
  const grouped: Record<string, Market[]> = {};
  const filteredData = data.filter(
    (item) =>
      item &&
      [MARKET_STATUS.ACTIVE, MARKET_STATUS.SUSPENDED].includes(item.status) &&
      item.selections.length,
  ) as Market[];
  const copy = [...filteredData];

  for (const item of copy) {
    const { marketId, line, id, name } = item;

    if (line === '') {
      grouped[id] = [item];
    } else {
      if (!grouped[marketId + '-' + name]) {
        grouped[marketId + '-' + name] = [];
      }

      grouped[marketId + '-' + name] = [
        ...(grouped[marketId + '-' + name] || []),
        item,
      ];
    }
  }

  for (const marketId in grouped) {
    const marketItems = grouped[marketId];

    if (marketItems && marketItems.length > 1) {
      marketItems.sort((a, b) => {
        const lineA = parseFloat(a.line || '0');
        const lineB = parseFloat(b?.line || '0');

        if (lineA < lineB) return -1;
        if (lineA > lineB) return 1;

        return 0;
      });
    }
  }

  const sortableArray = Object.keys(grouped).map((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const orderId = grouped[key][0].orderId;

    return {
      key,
      orderId,
    };
  });

  sortableArray.sort((a, b) => (a?.orderId || 0) - (b?.orderId || 0));
  const sortedObject = {};

  sortableArray.forEach((item, index) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortedObject[index] = grouped[item.key];
  });

  return sortedObject;
}
