import { useEffect } from 'react';

import { useLazyGetUserLimits } from '~api/auth/authQueries';
import {
  PaymentSettings,
  SportsBettingConfig,
  WebsiteSettingsResponse,
} from '~api/partner/types';
import { useAppDispatch, useAppSelector } from '~store';
import { updateWebsiteSettings } from '~store/slices/settingsSlice';
import {
  selectIsUserLoggedIn,
  selectIsUserProfileLoaded,
} from '~store/slices/userSlice';

export const useLoadUserLimits = (isSettingsLoaded: boolean) => {
  const dispatch = useAppDispatch();
  const { lazyGetUserLimitsQuery } = useLazyGetUserLimits();

  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const isUserProfileLoaded = useAppSelector(selectIsUserProfileLoaded);

  useEffect(() => {
    if (isUserLoggedIn && isSettingsLoaded) {
      const loadUserLimits = async () => {
        try {
          const {
            minStake,
            maxStake,
            minStakePreMatch,
            maxStakePreMatch,
            minStakeInPlay,
            maxStakeInPlay,
            maxWinAmount,
            duplicateBetsCountLimit,
            sportMinSelectionCount,
            sportMaxSelectionCount,
            depositLimitMin,
            depositLimitMax,
            withdrawalLimitMin,
            withdrawalLimitMax,
          } = await lazyGetUserLimitsQuery().unwrap();

          const newBetsConfig: Partial<SportsBettingConfig> = {};
          const newPaymentSettings: Partial<PaymentSettings> = {};
          const otherSettings: Partial<WebsiteSettingsResponse> = {};

          if (minStake) {
            newBetsConfig.sportMinStake = minStake;
          }

          if (maxStake) {
            newBetsConfig.sportMaxStake = maxStake;
          }

          if (minStakePreMatch) {
            newBetsConfig.minStakePreMatch = minStakePreMatch;
          }

          if (maxStakePreMatch) {
            newBetsConfig.maxStakePreMatch = maxStakePreMatch;
          }

          if (minStakeInPlay) {
            newBetsConfig.minStakeInPlay = minStakeInPlay;
          }

          if (maxStakeInPlay) {
            newBetsConfig.maxStakeInPlay = maxStakeInPlay;
          }

          if (sportMinSelectionCount) {
            newBetsConfig.sportMinSelectionCount = sportMinSelectionCount;
          }

          if (sportMaxSelectionCount) {
            newBetsConfig.sportMaxSelectionCount = sportMaxSelectionCount;
          }

          if (maxWinAmount) {
            otherSettings.maxWinAmount = maxWinAmount;
          }

          if (duplicateBetsCountLimit) {
            otherSettings.duplicateBetsCountLimit = duplicateBetsCountLimit;
          }

          if (depositLimitMax) {
            newPaymentSettings.depositLimitMax = depositLimitMax;
          }

          if (depositLimitMin) {
            newPaymentSettings.depositLimitMin = depositLimitMin;
          }

          if (withdrawalLimitMax) {
            newPaymentSettings.withdrawalLimitMax = withdrawalLimitMax;
          }

          if (withdrawalLimitMin) {
            newPaymentSettings.withdrawalLimitMin = withdrawalLimitMin;
          }

          dispatch(
            updateWebsiteSettings({
              otherSettings,
              newBetsConfig,
              newPaymentSettings,
            }),
          );
        } catch (e) {
          console.log(e);
        }
      };

      loadUserLimits();
    }
  }, [isUserProfileLoaded, isSettingsLoaded]);
};
