import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Text } from '~components/atoms/Typography';

export const StyledUserProfileInput = styled(Input, {
  height: '32px',
  borderRadius: '$6',
  border: '1px solid $gray',
});

export const StyledBalanceInfoText = styled(Text, {
  fontSize: '$14 !important',
  lineHeight: '$24 !important',
  '@xs_sm': {
    lineHeight: '$20 !important',
  },
});

export const StyledBalanceInfoWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '$6',
  backgroundColor: '$greenBlue',
  p: '$1 $2',
  '@xs_sm': {
    p: '$2 $3',
  },
});

export const StyledBalanceInfoButton = styled(Button, {
  width: '100%',
  borderRadius: '$6 !important',
  py: '6px !important',
  '@xs_sm': {
    py: '$2 !important',
  },
});

export const StyledBalanceInfoButtonText = styled(Text, {
  fontSize: '$14 !important',
  lineHeight: '$20 !important',
  color: '$buttonTextColor !important',
  fontWeight: '$bold !important',
  textAlign: 'center !important',
  '@xs_sm': {
    fontSize: '$16 !important',
  },
});
