import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~store'; // Adjust the import path to where your RootState is defined

/*  Getters */
export const getMobileState = (state: RootState) => state.mobileState;

/* Selectors */
export const selectActiveSportTabGroup = createSelector(
  [getMobileState],
  (mobileState) => mobileState.activeSportTabGroup,
);

export const selectIsFavoriteSelected = createSelector(
  [getMobileState],
  (mobileState) => mobileState.isFavoriteSelected,
);
