import { useEffect, useState } from 'react';

import { useIdVerify } from '~api/user/userMutations';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useRouterQuery } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog, openDialog } from '~store/slices/globalDialogSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';
import { updateProfile } from '~store/slices/userSlice';
import { Exception } from '~types/general';

import { VERIFY_PERSONAL_DETAILS_FIELDS } from './constants';

const defaultFormStateTemplate: Record<VERIFY_PERSONAL_DETAILS_FIELDS, string> =
  {
    [VERIFY_PERSONAL_DETAILS_FIELDS.FIRST_NAME]: '',
    [VERIFY_PERSONAL_DETAILS_FIELDS.LAST_NAME]: '',
    [VERIFY_PERSONAL_DETAILS_FIELDS.MIDDLE_NAME]: '',
    [VERIFY_PERSONAL_DETAILS_FIELDS.ID_NUMBER]: '',
  };

export const useVerifyPersonalDetails = () => {
  const { profile } = useAppSelector((state) => state.userState);
  const defaultFormState: Record<VERIFY_PERSONAL_DETAILS_FIELDS, string> = {
    ...defaultFormStateTemplate,
  };

  Object.keys(defaultFormState).forEach((key) => {
    defaultFormState[key as VERIFY_PERSONAL_DETAILS_FIELDS] =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      profile[key] || '';
  });
  const dispatch = useAppDispatch();
  const [formState, setFormState] = useState(defaultFormState);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { idVerifyMutation, idVerifyIsLoading } = useIdVerify();
  const { isMobileOrTablet } = useMedia();
  const { navigateWithHistory } = useRouterQuery();
  const handleFormValueChange = (
    name: VERIFY_PERSONAL_DETAILS_FIELDS,
    value: string,
  ) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const keys =
      (Object.keys(formState) as VERIFY_PERSONAL_DETAILS_FIELDS[]) || [];

    let isFormValid = true;

    keys.forEach((key) => {
      if (
        (!formState[key] || !formState[key]?.length) &&
        key !== VERIFY_PERSONAL_DETAILS_FIELDS.MIDDLE_NAME
      ) {
        isFormValid = false;
      }
    });

    setIsFormValid(isFormValid);

    return () => {
      setIsSuccess(false);
      setIsProcessed(false);
    };
  }, [formState]);

  const handleButtonClick = async () => {
    try {
      const isVerified = await idVerifyMutation(formState).unwrap();

      setIsSuccess(isVerified);
      setIsProcessed(true);
    } catch (e) {
      const errData = e as Exception;

      setIsProcessed(true);
      setErrorMessage(errData.data.message[0] as string);
      setIsSuccess(false);
    }
  };

  const handleContinue = () => {
    if (isSuccess) {
      dispatch(updateProfile({ personalInfoConfirmed: true, ...formState }));
    }

    dispatch(closeDialog());
    if (!isMobileOrTablet) {
      dispatch(openDialog(DIALOGS.USER_PROFILE));
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.WITHDRAW,
        }),
      );
    } else {
      navigateWithHistory(`${ROUTE_TAB_NAMES.PAYMENTS_WITHDRAW}/`);
    }

    setIsProcessed(false);
    setIsSuccess(false);
  };

  return {
    profile,
    formState,
    isFormValid,
    isProcessed,
    isSuccess,
    isLoading: idVerifyIsLoading,
    errorMessage,
    handleContinue,
    handleButtonClick,
    handleFormValueChange,
  };
};
