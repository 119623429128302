import { parseWebsocketMessage } from '~proto/messageParser';
import { getActionMessage } from '~utils/eventsSocketUtils';

type EventCallback = (data: unknown) => void;
type EventCallbacks = Record<string, EventCallback>;

export class EventEmitter {
  private events: Record<string, EventCallback[]> = {};

  on(event: string, listener: EventCallback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event]!.push(listener);
  }

  off(event: string, listener: EventCallback) {
    if (!this.events[event]) return;

    this.events[event] = this.events[event]!.filter((l) => l !== listener);

    if (this.events[event]!.length === 0) {
      delete this.events[event];
    }
  }

  emit(event: string, data: unknown) {
    const listeners = this.events[event];

    if (listeners) {
      listeners.forEach((listener) => listener(data));
    }
  }
}

class SocketEventsService {
  private _socket: WebSocket | null;
  private _eventEmitter: EventEmitter;

  constructor(socket: WebSocket | null, eventEmitter?: EventEmitter) {
    this._socket = socket;
    this._eventEmitter = eventEmitter || new EventEmitter();

    if (this._socket) {
      this._socket.addEventListener('message', this.handleMessage.bind(this));
    }
  }

  get socket(): WebSocket | null {
    return this._socket;
  }

  get eventEmitter(): EventEmitter {
    return this._eventEmitter;
  }

  private async handleMessage(event: MessageEvent): Promise<void> {
    const res = await parseWebsocketMessage(event);

    if (!res) throw Error('WebSocket response invalid');

    const { type: eventType, data } = res;

    this._eventEmitter.emit(eventType, data);
  }

  addListener(eventCallbacks: EventCallbacks): void {
    Object.entries(eventCallbacks).forEach(([event, callback]) => {
      if (callback) {
        this._eventEmitter.on(event, callback);
      }
    });
  }

  removeListener(eventCallbacks: EventCallbacks): void {
    Object.entries(eventCallbacks).forEach(([event, callback]) => {
      if (callback) {
        this._eventEmitter.off(event, callback);
      }
    });
  }

  sendMessage(event: string | number, data: unknown): void {
    if (this._socket) {
      this._socket.send(
        getActionMessage(+event, data as Record<string, unknown>),
      );
    }
  }
}

export default SocketEventsService;
