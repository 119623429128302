import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { SportEventItem, SportEventItems } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Checkbox } from '~components/atoms/Checkbox';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';
import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionRoot,
  MultiAccordionTrigger,
} from '~components/molecules/MultiAccordion';
import { EVENT_STATUS } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { isEmptyObject } from '~utils/objectHelpers';

import { SearchResult } from './SearchResult';
import {
  StyledLabelAdditionalText,
  StyledSearchResultListHeader,
  StyledSearchResultListWrapper,
} from './styled.components';

export interface SearchResultListProps {
  searchEvents: SportEventItems;
  isRelative?: boolean;
}

type GroupedBySportEventsData = Record<string, SportEventItem[]>;
type GroupedBySportEvents = {
  data: GroupedBySportEventsData;
  liveCount: number;
  prematchCount: number;
};

function groupBySportName(
  events: SportEventItem[],
  showLive: boolean,
  showPrematch: boolean,
): GroupedBySportEvents {
  let liveCount = 0;
  let prematchCount = 0;

  const data = events.reduce((groupedEvents, event) => {
    if (event.status === EVENT_STATUS.IN_PROGRESS) {
      liveCount++;
      if (!showLive) return groupedEvents;
    } else {
      prematchCount++;
      if (!showPrematch) return groupedEvents;
    }

    if (!groupedEvents[event.sportName]) {
      groupedEvents[event.sportName] = [];
    }

    groupedEvents[event.sportName]?.push(event);

    return groupedEvents;
  }, {} as GroupedBySportEventsData);

  return {
    data,
    liveCount,
    prematchCount,
  };
}

export const SearchResultList = memo(
  ({ isRelative = false, searchEvents }: SearchResultListProps) => {
    const { localized } = useTranslation();
    const [results, setResults] = useState<GroupedBySportEvents>(
      groupBySportName(searchEvents, true, true),
    );
    const [showLive, setShowLive] = useState(true);
    const [showPrematch, setShowPrematch] = useState(true);
    const [expandedValues, setExpandedValues] = useState<string[]>([]);

    const { liveCount, prematchCount, data } = results;

    useEffect(() => {
      const results = groupBySportName(searchEvents, showLive, showPrematch);

      setResults(results);
      setExpandedValues(Object.keys(results.data));
    }, [searchEvents, showLive, showPrematch]);

    const totalCount = useMemo(() => {
      if (showLive && !showPrematch) {
        return liveCount;
      }

      if (showPrematch && !showLive) {
        return prematchCount;
      }

      return prematchCount + liveCount;
    }, [prematchCount, liveCount, showPrematch, showLive]);

    const itemClickHandler = useCallback(
      (key: string) => {
        const isExpanded = expandedValues.includes(key);

        setExpandedValues((prev) =>
          isExpanded ? prev.filter((item) => item !== key) : [...prev, key],
        );
      },
      [expandedValues],
    );

    return (
      <StyledSearchResultListWrapper isRelative={isRelative}>
        <ScrollArea>
          <StyledSearchResultListHeader>
            <Box flexCol>
              <Box flexRow gap={4} css={{ marginTop: '$4' }}>
                <Checkbox
                  checked={showLive}
                  label={
                    <Text
                      css={{
                        fontSize: '$14',
                        lineHeight: '$20',
                        display: 'flex',
                        gap: '$1',
                      }}
                    >
                      {localized('search.showLive')}
                      <StyledLabelAdditionalText>
                        {`(${liveCount})`}
                      </StyledLabelAdditionalText>
                    </Text>
                  }
                  defaultChecked={showLive}
                  onCheckedChange={(checked) => {
                    setShowLive(checked as boolean);
                  }}
                />
                <Checkbox
                  checked={showPrematch}
                  label={
                    <Text
                      css={{
                        fontSize: '$14',
                        lineHeight: '$20',
                        display: 'flex',
                        gap: '$1',
                      }}
                    >
                      {localized('search.showPrematch')}
                      <StyledLabelAdditionalText>
                        {`(${prematchCount})`}
                      </StyledLabelAdditionalText>
                    </Text>
                  }
                  defaultChecked={showPrematch}
                  onCheckedChange={(checked) => {
                    setShowPrematch(checked as boolean);
                  }}
                />
              </Box>
            </Box>
            <Text
              level="14-20"
              color="grayMedium"
              css={{
                my: '$3',
              }}
            >
              {isEmptyObject(data)
                ? localized('search.results.noResults')
                : `${localized('search.results.title')}: ${totalCount}`}
            </Text>
          </StyledSearchResultListHeader>
          {
            <MultiAccordionRoot
              type="multiple"
              role={MULTI_ACCORDION_ROLES.PARENT}
              value={expandedValues}
            >
              {Object.entries(data).map(([key, items]) => {
                if (!items) return null;

                return (
                  <MultiAccordionItem
                    key={key}
                    value={key}
                    onClick={() => {
                      itemClickHandler(key);
                    }}
                  >
                    <MultiAccordionTrigger
                      title={key}
                      icon={items[0]?.sportId}
                      count={items.length}
                      role={MULTI_ACCORDION_ROLES.PARENT}
                    />
                    <MultiAccordionContent
                      role={MULTI_ACCORDION_ROLES.CHILD}
                      css={{
                        margin: '$1',
                        padding: '$3',
                        borderRadius: '$8',
                        '>div': {
                          padding: '0 !important',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '$1',
                        },
                      }}
                    >
                      {items.map(
                        ({
                          id: eventId,
                          name,
                          startDate,
                          leagueName,
                          status,
                          countryId,
                          leagueId,
                          sportId,
                        }) => (
                          <SearchResult
                            key={eventId}
                            sportId={sportId}
                            countryId={countryId}
                            leagueId={leagueId}
                            eventId={eventId}
                            leagueName={leagueName}
                            eventTitle={name}
                            eventDate={startDate}
                            status={status}
                          />
                        ),
                      )}
                    </MultiAccordionContent>
                  </MultiAccordionItem>
                );
              })}
            </MultiAccordionRoot>
          }
        </ScrollArea>
      </StyledSearchResultListWrapper>
    );
  },
);
