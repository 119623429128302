import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Checkbox } from '~components/atoms/Checkbox';
import { Input } from '~components/atoms/Input';
import { Label } from '~components/atoms/Label';
import { LabelWithOppositeText } from '~components/atoms/LabelWithOppositeText';
import { PasswordRequirements } from '~components/atoms/PasswordRequirements';
import { PasswordRequirementsPopover } from '~components/atoms/PasswordRequirementsPopover';
import { PasswordStrengthIndicator } from '~components/atoms/PasswordStrengthIndicator';
import { PhoneNumberField } from '~components/atoms/PhoneNumberField';
import { Separator } from '~components/atoms/Separator';
import { ErrorMessage, Text } from '~components/atoms/Typography';
import {
  SIGNUP_FORM_DEFAULT_VALUES,
  USERNAME_MAX_LIMIT,
  USERNAME_MIN_LIMIT,
} from '~constants/auth';
import { useMedia } from '~hooks/useMedia';
import { usePasswordCharacteristics } from '~hooks/usePasswordCharacteristics';
import { useTranslation } from '~hooks/useTranslation';
import { EyeIcon, EyeSlashIcon } from '~icons';
import { WaBetonly, WaSemabet } from '~icons/shareSocialMedias';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { selectIsUganda } from '~store/slices/selectors/settings';
import { BACKEND_ERROR_CODES } from '~utils/backendErrors';
import {
  keepDigitsAndLetters,
  removeEmptySpaces,
  removeNotChars,
} from '~utils/sanitizers';

import { SIGNUP_FORM_FIELDS, SignupFormInputs } from '../../types';

// import { WhatsappLoginButton } from '../WhatsappLoginButton';
import { SignUpTabList } from './SignUpTabList';

interface ProvideDetailsProps {
  isRegisterUserLoading: boolean;
  isRegisterWhatsAppLoading: boolean;
  onNextTab: () => void;
  onSignIn: () => void;
  handleSignInWithWhatsApp: () => void;
}

export const ProvideDetailsTab = ({
  isRegisterUserLoading,
  isRegisterWhatsAppLoading,
  onNextTab,
  onSignIn,
  handleSignInWithWhatsApp,
}: ProvideDetailsProps) => {
  const { register, formState, watch, setValue, clearErrors, trigger } =
    useFormContext<SignupFormInputs>();
  const { errors } = formState;

  const { localized, localizedError } = useTranslation();
  const { isMobile, isMobileOrTablet } = useMedia();

  const { onBlur: onPasswordBlur } = register(
    SIGNUP_FORM_FIELDS.PASSWORD_FIELD,
  );
  const isUganda = useAppSelector(selectIsUganda);
  const [isPressed, setIsPressed] = useState(false);
  const [showWhatsapp] = useState(false);
  const [isPasswordPopoverOpen, setIsPasswordPopoverOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isUserNameFocused, setIsUserNameFocused] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  const { usernameField, passwordField } = watch();
  const userNameCounterActive = isUserNameFocused || usernameField.length > 0;
  const { passwordStrength, localizedCharacteristicsItems, isValidPassword } =
    usePasswordCharacteristics(passwordField);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { minimumAge } = useAppSelector((state) => state.settings);
  const [agreedToTerms, setAgreedToTerms] = useState(
    SIGNUP_FORM_DEFAULT_VALUES.agreeToTermsField,
  );

  const isErrorCodeMessage = Object.values(BACKEND_ERROR_CODES).includes(
    errors.root?.incompleteOrIncorrectInfo?.message as BACKEND_ERROR_CODES,
  );

  const handlePolicyClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(closeDialog());
    navigate('/info/privacy_policy');
  };

  const handleConditionsClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(closeDialog());
    navigate('/info/terms_and_conditions');
  };

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = removeNotChars(event.target.value);

    if (sanitizedValue.length >= USERNAME_MIN_LIMIT) {
      clearErrors(SIGNUP_FORM_FIELDS.USERNAME_FIELD);
    }

    if (sanitizedValue.length <= USERNAME_MAX_LIMIT) {
      setValue(SIGNUP_FORM_FIELDS.USERNAME_FIELD, sanitizedValue);
    } else {
      setValue(
        SIGNUP_FORM_FIELDS.USERNAME_FIELD,
        sanitizedValue.slice(0, USERNAME_MAX_LIMIT),
      );
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = removeEmptySpaces(event.target.value);

    clearErrors(SIGNUP_FORM_FIELDS.PASSWORD_FIELD);
    setValue(SIGNUP_FORM_FIELDS.PASSWORD_FIELD, sanitizedValue);
  };

  const handlePromoCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const sanitizedValue = keepDigitsAndLetters(event.target.value);

    clearErrors(SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD);
    setValue(SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD, sanitizedValue);
  };

  const updatePhoneNumber = (input: string): void => {
    setPhoneNumber(input);
    setValue(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD, input);
  };

  const handleNextTab = async () => {
    const output = await trigger();

    clearErrors('root');

    if (output) {
      onNextTab();
    }
  };

  const signUpFormContent = !showWhatsapp ? (
    <>
      {/* {isUganda && (
        <>
          <WhatsappLoginButton
            showWhatsapp={showWhatsapp}
            setShowWhatsapp={setShowWhatsapp}
            register={true}
          />
          <Separator verticalSpace={6} shrinkOut={isMobile ? 4 : 7} />
        </>
      )} */}

      <Box
        css={{
          position: 'relative',
          mb: '$3',
        }}
      >
        {/* {isUganda && (
          <Text
            css={{
              p: '$0 $3',
              position: 'absolute',
              transform: 'translateX(-50%)',
              left: '50%',
              top: -36,
              background: '$black',
              whiteSpace: 'nowrap',
            }}
            level={'16-24'}
          >
            {localized('signUp.or')}
          </Text>
        )} */}
        {((!isErrorCodeMessage && errors.root?.incompleteOrIncorrectInfo) ||
          errors.root?.serverError) && (
          <Box
            css={{
              position: 'absolute',
              top: '-$3',
              width: '100%',
            }}
          >
            <ErrorMessage
              type="centeredError"
              message={
                (errors.root?.incompleteOrIncorrectInfo?.message ||
                  errors.root?.serverError?.message)!
              }
            />
          </Box>
        )}
        {!isUganda && (
          <>
            <LabelWithOppositeText
              htmlFor={SIGNUP_FORM_FIELDS.USERNAME_FIELD}
              oppositeText={
                errors.usernameField && errors.usernameField.message
              }
              error={!!errors.usernameField}
            >
              {localized('signUp.fields.username.label')}
            </LabelWithOppositeText>
            <Input
              id={SIGNUP_FORM_FIELDS.USERNAME_FIELD}
              placeholder={localized('signUp.fields.username.placeholder')}
              {...register(SIGNUP_FORM_FIELDS.USERNAME_FIELD, {
                validate: {
                  hasInvalidCharactersNumber: (value) =>
                    (value.length <= USERNAME_MAX_LIMIT &&
                      value.length >= USERNAME_MIN_LIMIT) ||
                    localizedError('username.mustBeMinToMaxCharsLong', {
                      min: USERNAME_MIN_LIMIT,
                      max: USERNAME_MAX_LIMIT,
                    }),
                },
              })}
              onChange={handleUserNameChange}
              onFocus={() => setIsUserNameFocused(true)}
              onBlur={() => setIsUserNameFocused(false)}
              css={{ color: !errors.usernameField ? '$white' : '$red' }}
            />
          </>
        )}
        <Text
          level="sm-3"
          css={{
            position: 'absolute',
            right: 0,
            top: '$7',
            color: errors.usernameField
              ? '$red'
              : userNameCounterActive
                ? '$white'
                : '$grayMedium',
            p: '0 $3',
          }}
        >
          {`${usernameField?.length ?? 0}/${USERNAME_MAX_LIMIT}`}
        </Text>
      </Box>
      <Box
        css={{
          mb: '$3',
        }}
      >
        <LabelWithOppositeText
          htmlFor={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
          oppositeText={
            errors.phoneNumberField && errors.phoneNumberField.message
          }
          error={!!errors.phoneNumberField}
        >
          {localized('signUp.fields.phoneNumber.label')}
        </LabelWithOppositeText>
        <PhoneNumberField
          phoneNumberId={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
          phoneNumber={phoneNumber}
          setPhoneNumber={updatePhoneNumber}
          isValidPhoneNumber={isValidPhoneNumber}
          setIsValidPhoneNumber={setIsValidPhoneNumber}
          {...register(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD)}
        />
      </Box>
      <Box>
        <Box flexRow justifyContentBetween>
          <Label htmlFor={SIGNUP_FORM_FIELDS.PASSWORD_FIELD}>
            {localized('signUp.fields.password.label')}
          </Label>
          {!!passwordField && !errors.passwordField && isValidPassword && (
            <PasswordStrengthIndicator
              strength={passwordStrength}
              css={{ margin: '0 $3 $1 $2' }}
            />
          )}
          {!!errors.passwordField && (
            <ErrorMessage message={errors.passwordField.message!} />
          )}
        </Box>
        <Box
          css={{
            position: 'relative',
          }}
        >
          <Input
            autoComplete="new-password"
            id={SIGNUP_FORM_FIELDS.PASSWORD_FIELD}
            placeholder={localized('signUp.fields.password.placeholder')}
            {...register(SIGNUP_FORM_FIELDS.PASSWORD_FIELD)}
            onFocus={() => setIsPasswordPopoverOpen(true)}
            onBlur={(e) => {
              onPasswordBlur(e);
              setIsPasswordPopoverOpen(false);
            }}
            onChange={handlePasswordChange}
            type={showPassword ? 'text' : 'password'}
          />
          <Box
            css={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              p: '$2 $3',
              cursor: 'pointer',
              color: '$grayMedium',
              '&:hover': {
                color: '$white',
              },
            }}
            role="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
          </Box>
          {isMobile ? (
            isPasswordPopoverOpen ? (
              <Box
                css={{
                  p: '$2 $3',
                  margin: '$2 0',
                  backgroundColor: '$black',
                  borderRadius: '$8',
                  border: '1px solid $grayDark',
                }}
              >
                <PasswordRequirements
                  localizedCharacteristicsItems={localizedCharacteristicsItems}
                />
              </Box>
            ) : null
          ) : (
            <PasswordRequirementsPopover
              isPasswordPopoverOpen={isPasswordPopoverOpen}
              localizedCharacteristicsItems={localizedCharacteristicsItems}
            />
          )}
        </Box>
      </Box>
      <Separator verticalSpace={6} shrinkOut={isMobile ? 4 : 7} />
      <Box
        css={{
          width: '100%',
          mb: '$5',
          position: 'relative',
        }}
      >
        <Text
          css={{
            p: '$0 $3',
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            top: -36,
            background: '$black',
            whiteSpace: 'nowrap',
          }}
          level={'16-24'}
        >
          {localized('signUp.havePromotionCode')}
        </Text>

        <LabelWithOppositeText
          htmlFor={SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD}
          oppositeText={
            errors.promotionCodeField
              ? errors.promotionCodeField.message
              : localized('signUp.fields.promoCode.optional')
          }
          error={!!errors.promotionCodeField}
        >
          {localized('signUp.fields.promoCode.label')}
        </LabelWithOppositeText>
        <Input
          id={SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD}
          placeholder={localized('signUp.fields.promoCode.placeholder')}
          {...register(SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD)}
          onChange={handlePromoCodeChange}
        />
      </Box>
      <Button
        fullWidth
        onClick={handleNextTab}
        isLoading={isRegisterUserLoading || isRegisterWhatsAppLoading}
        css={{
          marginTop: '$5',
        }}
      >
        {localized('buttons.next')}
      </Button>
    </>
  ) : (
    <>
      <Box
        css={{
          position: 'relative',
          mb: '$3',
        }}
      >
        {(!isErrorCodeMessage ||
          errors.root?.incompleteOrIncorrectInfo ||
          errors.root?.serverError) && (
          <Box
            css={{
              position: 'absolute',
              top: '-$3',
              width: '100%',
            }}
          >
            <ErrorMessage
              type="centeredError"
              message={
                (errors.root?.incompleteOrIncorrectInfo?.message ||
                  errors.root?.serverError?.message)!
              }
            />
          </Box>
        )}
        {!isUganda && (
          <>
            <LabelWithOppositeText
              htmlFor={SIGNUP_FORM_FIELDS.USERNAME_FIELD}
              oppositeText={
                errors.usernameField && errors.usernameField.message
              }
              error={!!errors.usernameField}
            >
              {localized('signUp.fields.username.label')}
            </LabelWithOppositeText>
            <Input
              id={SIGNUP_FORM_FIELDS.USERNAME_FIELD}
              placeholder={localized('signUp.fields.username.placeholder')}
              {...register(SIGNUP_FORM_FIELDS.USERNAME_FIELD, {
                validate: {
                  hasInvalidCharactersNumber: (value) =>
                    (value.length <= USERNAME_MAX_LIMIT &&
                      value.length >= USERNAME_MIN_LIMIT) ||
                    localizedError('username.mustBeMinToMaxCharsLong', {
                      min: USERNAME_MIN_LIMIT,
                      max: USERNAME_MAX_LIMIT,
                    }),
                },
              })}
              onChange={handleUserNameChange}
              onFocus={() => setIsUserNameFocused(true)}
              onBlur={() => setIsUserNameFocused(false)}
              css={{ color: !errors.usernameField ? '$white' : '$red' }}
            />
          </>
        )}
        <Text
          level="sm-3"
          css={{
            position: 'absolute',
            right: 0,
            top: '$7',
            color: errors.usernameField
              ? '$red'
              : userNameCounterActive
                ? '$white'
                : '$grayMedium',
            p: '0 $3',
          }}
        >
          {`${usernameField?.length ?? 0}/${USERNAME_MAX_LIMIT}`}
        </Text>
      </Box>
      <Box
        css={{
          mb: '$3',
        }}
      >
        <LabelWithOppositeText
          htmlFor={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
          oppositeText={
            errors.phoneNumberField && errors.phoneNumberField.message
          }
          error={!!errors.phoneNumberField}
        >
          {localized('signUp.fields.phoneNumber.label')}
        </LabelWithOppositeText>
        <PhoneNumberField
          phoneNumberId={SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD}
          phoneNumber={phoneNumber}
          setPhoneNumber={updatePhoneNumber}
          isValidPhoneNumber={isValidPhoneNumber}
          setIsValidPhoneNumber={setIsValidPhoneNumber}
          {...register(SIGNUP_FORM_FIELDS.PHONE_NUMBER_FIELD)}
        />
      </Box>
      <Separator verticalSpace={6} shrinkOut={isMobile ? 4 : 7} />
      <Box
        css={{
          width: '100%',
          mb: '$5',
          position: 'relative',
        }}
      >
        <Text
          css={{
            p: '$0 $3',
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            top: -36,
            background: '$black',
            whiteSpace: 'nowrap',
          }}
          level={'16-24'}
        >
          {localized('signUp.havePromotionCode')}
        </Text>

        <LabelWithOppositeText
          htmlFor={SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD}
          oppositeText={
            errors.promotionCodeField
              ? errors.promotionCodeField.message
              : localized('signUp.fields.promoCode.optional')
          }
          error={!!errors.promotionCodeField}
        >
          {localized('signUp.fields.promoCode.label')}
        </LabelWithOppositeText>
        <Input
          id={SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD}
          placeholder={localized('signUp.fields.promoCode.placeholder')}
          {...register(SIGNUP_FORM_FIELDS.PROMOTION_CODE_FIELD)}
          onChange={handlePromoCodeChange}
        />
      </Box>
      <Separator
        verticalSpace={isMobile ? 3 : 6}
        shrinkOut={isMobile ? 4 : 7}
      />
      <Box css={{ position: 'relative', pb: '$3' }}>
        <Checkbox
          id={SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD}
          checked={agreedToTerms}
          isError={!!errors.agreeToTermsField}
          onCheckedChange={() => {
            setAgreedToTerms(!agreedToTerms);
            setValue(SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD, !agreedToTerms, {
              shouldValidate: true,
            });
          }}
          {...register(SIGNUP_FORM_FIELDS.AGREE_TO_TERMS_FIELD, {
            validate: {
              notAgreedToTerms: (value) => {
                if (!value) {
                  return localizedError('agreeToTerms.mustBeTrue');
                }

                return true;
              },
            },
          })}
          label={
            <Text level="xxxs-3">
              {localized('signUp.fields.terms.beginning', { minimumAge })}
              <Text
                as="span"
                level="xxxs-3"
                color="green"
                underline
                css={{ p: '$1' }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onClick={handleConditionsClick}
              >
                {localized('signUp.fields.terms.linkTerms')}
              </Text>
              <Text
                as="span"
                level="xxxs-3"
                color="white"
                underline
                css={{ p: '$1' }}
              >
                ,
              </Text>
              <Text
                as="span"
                level="xxxs-3"
                color="green"
                underline
                css={{ p: '$1' }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onClick={handlePolicyClick}
              >
                {localized('signUp.fields.terms.linkPrivacy')}
              </Text>
              {localized('signUp.fields.terms.ending')}
            </Text>
          }
        />
        {errors.agreeToTermsField && (
          <Box css={{ position: 'absolute', bottom: '-$3', width: '100%' }}>
            <ErrorMessage
              type="centeredError"
              message={errors.agreeToTermsField.message!}
            />
          </Box>
        )}
      </Box>
      <Button
        fullWidth
        onClick={handleSignInWithWhatsApp}
        isLoading={isRegisterUserLoading || isRegisterWhatsAppLoading}
        css={{
          marginTop: '$5',
          gap: '$1',
          alignItems: 'center',
          backgroundColor: '$success',
          color: '$white',
        }}
      >
        {isUganda ? <WaSemabet /> : <WaBetonly />}
        {localized('signUp.getLink')}
      </Button>
    </>
  );

  const headerText = showWhatsapp ? (
    <>
      <Text
        textAlign="center"
        textTransform="uppercase"
        fontWeight="medium"
        css={{
          fontSize: '$xl',
          lineHeight: '$44',
          mb: '$1',
          display: 'flex',
          gap: '$2',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {localized('signUp.whatsappTitle')}
        <Text
          textAlign="center"
          textTransform="uppercase"
          fontWeight="medium"
          color="success"
          css={{
            fontSize: '$xl',
            lineHeight: '$44',
          }}
        >
          WhatsApp
        </Text>
      </Text>
      <Text
        level="md-3"
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
      >
        {localized('signUp.whatsappSubTitle')}
      </Text>
      <Text
        level="md-3"
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
      >
        {localized('signUp.whatsappSubTitle2')}
      </Text>
    </>
  ) : (
    <>
      <Text
        textAlign="center"
        textTransform="uppercase"
        fontWeight="medium"
        css={{
          fontSize: '$xxl',
          lineHeight: '$44',
          mb: '$1',
        }}
      >
        {localized('signUp.title')}
      </Text>
      <Text
        level="md-3"
        fontWeight="medium"
        textAlign="center"
        textTransform="uppercase"
      >
        {localized('signUp.subTitle')}
      </Text>
    </>
  );

  return (
    <>
      {headerText}
      <SignUpTabList />
      {signUpFormContent}
      <Box
        css={{
          mt: '$4',
        }}
        flexRow={!isMobileOrTablet}
        flexCol={isMobileOrTablet}
        justifyCenter
        gap={1}
        alignCenter
      >
        <Text level="16-24" textTransform="uppercase" textAlign="center">
          {localized('signIn.haveAccount')}
        </Text>
        <Text
          level="16-24"
          textTransform="uppercase"
          textAlign="center"
          underline
          color="green"
          fontWeight={isPressed ? 'bold' : 'normal'}
          css={{
            cursor: 'pointer',
            '&:hover': {
              opacity: '0.7',
            },
          }}
          onMouseDown={() => setIsPressed(true)}
          onMouseUp={() => setIsPressed(false)}
          onClick={onSignIn}
        >
          {localized('buttons.signIn')}
        </Text>
      </Box>
    </>
  );
};
