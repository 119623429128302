import React, { memo } from 'react';

import { ToggleEventMarkets } from '~components/atoms/ToggleMarket/ToggleEventMarkets';
import { useAppDispatch, useAppSelector } from '~store';
import { setMainMarketsSelected } from '~store/slices/liveMenuSlice';

interface ToggleSportMarketProps {
  sportId: number;
  noMargin?: boolean;
}

export const ToggleSportMarket = memo(
  ({ sportId, noMargin }: ToggleSportMarketProps) => {
    const dispatch = useAppDispatch();
    const { mainMarketsSelected } = useAppSelector((state) => state.liveMenu);

    const handleValueChange = (marketId: string) => {
      dispatch(
        setMainMarketsSelected({
          sportId: sportId,
          marketId: parseInt(marketId),
        }),
      );
    };

    return (
      <ToggleEventMarkets
        sportId={sportId}
        noMargin={noMargin}
        variant="market"
        mainMarketsSelected={mainMarketsSelected}
        handleValueChange={handleValueChange}
      />
    );
  },
);
