import { useEffect, useMemo, useState } from 'react';

import { Countries, CountriesBySportParams } from '~api/category/types';
import { QUERY_PARAMS } from '~constants/common';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useAppDispatch, useAppSelector } from '~store';
import {
  addOpenedSport,
  setLoadingCountryId,
  setLoadingSportId,
  setOpenedSports,
} from '~store/slices/prematchMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { conditionalAdd, convertKeysToUpperCase } from '~utils/objectHelpers';

import { MULTI_ACCORDION_ROLES } from '../../MultiAccordion';
import { handleOnValueChangeHelper } from '../../utils';

export const useSportMenu = () => {
  const dispatch = useAppDispatch();
  const sportMenuRole = MULTI_ACCORDION_ROLES.PARENT;
  const { openedSports, sports, countriesData, dateToFilter } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const { isPrimaryDataLoaded, sendWsMessage } = useEventsLoadingSocket();
  const [loadingSportCountriesQueue, setLoadingSportCountriesQueue] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const language = useAppSelector(selectLanguage);

  const getCountriesDataMemo = useMemo(() => {
    return (sportId: number) => {
      return sportId in countriesData
        ? (countriesData[sportId] as Countries)
        : [];
    };
  }, [countriesData]);

  const handleOnValueChange = async (e: string[]) => {
    const { item: sportId, shouldFetchData } = handleOnValueChangeHelper({
      e,
      openOptions: openedSports,
      existingData: countriesData,
    });

    if (shouldFetchData) {
      setLoadingSportCountriesQueue([...loadingSportCountriesQueue, sportId]);
    } else {
      setTimeout(() => {
        dispatch(setOpenedSports(e));
      }, 0);
    }
  };

  useEffect(() => {
    if (loadingSportCountriesQueue.length === 0 || isLoading) return;
    const loadingSportCountriesQueueCopy = [...loadingSportCountriesQueue];
    const sportId = loadingSportCountriesQueueCopy.shift();

    if (!sportId || !isPrimaryDataLoaded) return;
    setIsLoading(true);

    const loadCountries = async () => {
      const params: CountriesBySportParams = { sportId: parseInt(sportId) };

      conditionalAdd(params, QUERY_PARAMS.DATE_TO, dateToFilter);
      dispatch(setLoadingSportId(+sportId));
      dispatch(setLoadingCountryId(null));
      sendWsMessage(ACTION_TYPE.GET_PREMATCH_SUB_MENU, {
        ...convertKeysToUpperCase(
          params as unknown as { [key: string]: unknown },
        ),
        Language: language,
      });
      setTimeout(() => {
        dispatch(addOpenedSport(sportId));
        setLoadingSportCountriesQueue((prev) =>
          prev.filter((item) => item !== sportId),
        );
        setIsLoading(false);
      }, 300);
    };

    loadCountries();
  }, [loadingSportCountriesQueue, isLoading, isPrimaryDataLoaded, language]);

  return {
    sports,
    sportMenuRole,
    openSports: openedSports,
    getCountriesDataMemo,
    handleOnValueChange,
  };
};
