import { Box } from '~components/atoms/Box';
import { useMedia } from '~hooks';
import { useIsCasinoWebsite } from '~hooks/useIsCasinoWebsite';

import { getNavigationItems } from '../../constants/navigationItems';
import { NavigationItem } from '../NavigationItem';

export const UserProfileNavigation = () => {
  const isCasinoWebsite = useIsCasinoWebsite();
  const { isMobileOrTablet } = useMedia();

  return (
    <Box
      flexCol
      alignStart
      gap={3}
      css={{
        p: '$3',
        borderRadius: '$8',
        backgroundColor: '$grayDark',
        color: '$grayMedium',
      }}
    >
      {getNavigationItems({ isCasinoWebsite, isMobileOrTablet }).map(
        ({ icon, title, value }) => (
          <NavigationItem
            key={title}
            value={value}
            icon={icon}
            title={title}
            showActive
          />
        ),
      )}
    </Box>
  );
};
