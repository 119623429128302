import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { LANGUAGE_TYPES } from '~constants/language';
import { RootState } from '~store';
export interface WebsiteSettings {
  timezone: string | null;
  odds: string;
  language: LANGUAGE_TYPES;
}

const initialState: WebsiteSettings = {
  timezone: null,
  odds: 'decimal',
  language: LANGUAGE_TYPES.English,
};

export const websiteSettingsSlice = createSlice({
  name: 'websiteSettings',
  initialState,
  reducers: {
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    setOdds: (state, action: PayloadAction<string>) => {
      state.odds = action.payload;
    },
    setLanguage: (state, action: PayloadAction<LANGUAGE_TYPES>) => {
      state.language = action.payload;
    },
  },
});

export const selectLanguage = createSelector(
  (state: RootState) => state.websiteSettings,
  (websiteSettings: WebsiteSettings) => websiteSettings.language,
);

export const { setTimezone, setOdds, setLanguage } =
  websiteSettingsSlice.actions;

export default websiteSettingsSlice.reducer;
