import { useCallback, useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import {
  PrematchUpcomingHighlightsData,
  TopPrematchDateGroupsIds,
} from '~constants/mainPage';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setScrollTopMainPage } from '~store/slices/mobileSlice';
import {
  setActiveMainHighlightsSportId,
  setIsAllLoaded,
  setUpcomingEventsLoading,
} from '~store/slices/prematchMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

import {
  preparePrematchUpcomingHighlightsData,
  SportOptions,
} from '../helpers';

import { useUpcomingHubConnection } from './useUpcomingHubConnection';

const limit = 20;

export const useMainPageMobile = () => {
  const { localized } = useTranslation();

  const dispatch = useAppDispatch();
  const { mobileMainPageTab, scrollTopMainPage } = useAppSelector(
    (state) => state.mobileState,
  );
  const {
    upcomingEvents,
    activeMainHighlightsSportId,
    upcomingEventsLoading,
    isAllLoaded,
  } = useAppSelector((state) => state.prematchMenu);
  const { liveHighlightsSports } = useAppSelector((state) => state.liveMenu);
  const { data: preparedData, sports: sportsDefault } =
    preparePrematchUpcomingHighlightsData(upcomingEvents || []);
  const [highlightsData, setHighlightsData] =
    useState<PrematchUpcomingHighlightsData>(preparedData);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const { mainMarketsSelected } = useAppSelector((state) => state.liveMenu);
  const [sports, setSports] = useState<SportOptions>(sportsDefault);
  const { isPrimaryDataLoaded, sendWsMessage } = useEventsLoadingSocket();
  const [activeEventGroups, setActiveEventGroups] =
    useState<TopPrematchDateGroupsIds>(
      highlightsData[activeMainHighlightsSportId as string] || [],
    );
  const language = useAppSelector(selectLanguage);

  useUpcomingHubConnection();

  let defaultMainSportMarket: MainMarket | undefined;

  if (mainMarkets.length && activeMainHighlightsSportId) {
    const mainSport = mainMarkets.find(
      (sport) => sport.id === parseInt(activeMainHighlightsSportId),
    );

    if (mainSport) {
      const markets = [...mainSport.markets];
      const marketSelected =
        markets.find(
          ({ id }) =>
            id === mainMarketsSelected[parseInt(activeMainHighlightsSportId)],
        ) || markets[0];

      if (marketSelected) {
        defaultMainSportMarket = marketSelected;
      } else {
        defaultMainSportMarket = markets[0];
      }
    }
  }

  const [mainSportMarket, setMainSportMarkets] = useState<MainMarket>(
    defaultMainSportMarket as MainMarket,
  );

  const handleActiveSportIdChange = useCallback(async (sportId: string) => {
    dispatch(setActiveMainHighlightsSportId(sportId));
    dispatch(setIsAllLoaded(false));

    loadUpcoming(sportId);
  }, []);

  const loadUpcoming = useCallback(
    async (sportId?: string) => {
      dispatch(setUpcomingEventsLoading(true));
      sendWsMessage(ACTION_TYPE.GET_UPCOMING, {
        SportId: Number(sportId),
        Language: language,
      });
    },
    [language],
  );

  const loadMoreUpcoming = useCallback(async () => {
    const currentSportDateGroups = upcomingEvents?.find(
      (sportData) => sportData.sportId === Number(activeMainHighlightsSportId),
    )?.dateGroups;

    const totalLoaded = currentSportDateGroups?.reduce(
      (acc, { matches }) => acc + matches.length,
      0,
    ) as number;

    sendWsMessage(ACTION_TYPE.GET_UPCOMING, {
      SportId: Number(activeMainHighlightsSportId),
      Page: Math.floor(totalLoaded / limit) + 1,
      Limit: limit,
      Language: language,
    });
  }, [upcomingEvents, activeMainHighlightsSportId, limit, language]);

  useEffect(() => {
    if (!isPrimaryDataLoaded || upcomingEvents) return;
    dispatch(setUpcomingEventsLoading(true));
    sendWsMessage(ACTION_TYPE.GET_UPCOMING, {
      Language: language,
    });
  }, [isPrimaryDataLoaded, upcomingEvents]);

  useEffect(() => {
    if (!upcomingEvents) return;
    const { data: preparedData, sports } =
      preparePrematchUpcomingHighlightsData(upcomingEvents);

    const activeSportIdValue =
      activeMainHighlightsSportId || (sports[0]?.id.toString() as string);

    dispatch(setActiveMainHighlightsSportId(activeSportIdValue));
    setSports(sports);
    setHighlightsData(preparedData);
    setActiveEventGroups(
      preparedData[activeSportIdValue as string] as TopPrematchDateGroupsIds,
    );
    dispatch(setUpcomingEventsLoading(false));
  }, [
    upcomingEvents,
    liveHighlightsSports,
    mobileMainPageTab,
    activeMainHighlightsSportId,
  ]);

  useEffect(() => {
    if (mainMarkets.length && activeMainHighlightsSportId) {
      const mainSport = mainMarkets.find(
        (sport) => sport.id === parseInt(activeMainHighlightsSportId),
      );

      if (mainSport) {
        const markets = [...mainSport.markets];
        const marketSelected =
          markets.find(
            ({ id }) =>
              id === mainMarketsSelected[parseInt(activeMainHighlightsSportId)],
          ) || markets[0];

        if (marketSelected) {
          setMainSportMarkets(marketSelected);
        } else {
          setMainSportMarkets(markets[0] as MainMarket);
        }
      }
    }
  }, [mainMarkets, activeMainHighlightsSportId, mainMarketsSelected]);

  useEffect(() => {
    if (scrollTopMainPage) {
      window.scrollTo(0, 0);
      dispatch(setScrollTopMainPage(false));
    }
  }, [scrollTopMainPage]);

  return {
    activeTab: mobileMainPageTab,
    activeSportId: activeMainHighlightsSportId,
    activeEventGroups,
    mainSportMarket,
    sports,
    isLoading: upcomingEventsLoading,
    isAllLoaded,
    localized,
    handleActiveSportIdChange,
    loadMore: loadMoreUpcoming,
  };
};
