import React from 'react';

import { TabsList } from '~components/atoms/Tabs';
import { useTranslation } from '~hooks/useTranslation';

import { USER_PROFILE_TABS } from '../UserProfileDialog';

export const userProfileTabs = [
  USER_PROFILE_TABS.PERSONAL_DETAILS,
  USER_PROFILE_TABS.ACCOUNT_SETTINGS,
  USER_PROFILE_TABS.RECENT_SESSIONS,
];

interface UserProfileTabListProps {
  withSlider?: boolean;
  uppercase?: boolean;
  withSeparator?: boolean;
  isMobileTabs?: boolean;
}

export const UserProfileTabList = ({
  uppercase = false,
  withSlider = false,
  withSeparator = false,
  isMobileTabs = false,
}: UserProfileTabListProps) => {
  const { localized } = useTranslation();

  return (
    <TabsList
      withSeparator={withSeparator}
      isMobileTabs={isMobileTabs}
      uppercase={uppercase}
      withSlider={withSlider}
      tabs={userProfileTabs}
      ariaLabel={localized('userProfile.personalProfileInfo.ariaLabel')}
    />
  );
};
