import React, { ReactNode, useState } from 'react';
import * as DropdownUI from '@radix-ui/react-dropdown-menu';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import { useIsCasinoWebsite } from '~hooks/useIsCasinoWebsite';
import { useTranslation } from '~hooks/useTranslation';
import {
  CopyToClipboardIcon,
  EyeIcon,
  EyeSlashIcon,
  GreenCheckIcon,
  LogoutIcon,
} from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setBetslipProcessed,
  setShowBetslipNotification,
} from '~store/slices/betslipSlice';
import { logout } from '~store/slices/userSlice';
import { setBalanceVisibility } from '~store/slices/userUISlice';

import { getNavigationItems } from '../../constants/navigationItems';
import { NavigationItem } from '../NavigationItem';

import {
  DropdownMenuItem,
  DropdownMenuSeparator,
  StyledDropdownMenuContent,
  StyledDropdownTrigger,
} from './styled.components';

interface UserProfileNavigationDropDownProps {
  children: ReactNode;
  onOpenChange?: (val: boolean) => void;
}

export const UserProfileNavigationDropDown = ({
  children,
  onOpenChange,
}: UserProfileNavigationDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { profile } = useAppSelector((state) => state.userState);
  const { isBalanceVisible } = useAppSelector((state) => state.userUIState);
  const { showBetslipNotification } = useAppSelector((state) => state.betslip);
  const { id, userName, phoneNumber } = profile || {};
  const dispatch = useAppDispatch();
  const { handleCopy, isCopied } = useCopyToClipboard();
  const isCasinoWebsite = useIsCasinoWebsite();
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    onOpenChange?.(open);
  };

  const handleCopyId = (e: React.MouseEvent) => {
    e.preventDefault();

    handleCopy(id);
  };

  const handleToggleBalanceVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setBalanceVisibility(!isBalanceVisible));
  };

  if (!profile) {
    return null;
  }

  const handleLogout = () => {
    if (showBetslipNotification) {
      dispatch(setShowBetslipNotification(false));
      dispatch(setBetslipProcessed(false));
    }

    dispatch(logout());
  };

  return (
    <DropdownUI.Root onOpenChange={handleOpenChange} open={isOpen}>
      <StyledDropdownTrigger asChild>
        <div>{children}</div>
      </StyledDropdownTrigger>
      <DropdownUI.Portal>
        <StyledDropdownMenuContent
          sideOffset={20}
          align="start"
          alignOffset={-210}
        >
          <DropdownMenuItem onClick={handleToggleBalanceVisibility}>
            <Text level="16-24" ellipsis>
              {userName || phoneNumber}
            </Text>
            {isBalanceVisible ? <EyeIcon /> : <EyeSlashIcon />}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleCopyId}>
            <Text level="16-24" ellipsis>
              ID: {id}
            </Text>
            <Box
              as="span"
              css={{
                mt: '$1',
              }}
            >
              {isCopied ? <GreenCheckIcon /> : <CopyToClipboardIcon />}
            </Box>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {getNavigationItems({ isCasinoWebsite, isMobileOrTablet }).map(
            ({ title, icon, value }) => (
              <DropdownMenuItem key={title}>
                <NavigationItem
                  icon={icon}
                  title={title}
                  value={value}
                  size="small"
                />
              </DropdownMenuItem>
            ),
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleLogout}>
            <LogoutIcon />
            <Text level="16-24" ellipsis>
              {localized('userProfile.menuTitles.logout')}
            </Text>
          </DropdownMenuItem>
        </StyledDropdownMenuContent>
      </DropdownUI.Portal>
    </DropdownUI.Root>
  );
};
