import React from 'react';

import { SportGroup } from '~api/category/types';
import { LeagueMenuList } from '~components/atoms/LeagueMenuList';

import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionTrigger,
} from '../../MultiAccordion';
import { SPORT_GROUP_COMPONENTS } from '../constants';

export interface TournamentsGroupProps {
  sportGroupsMemo: SportGroup[];
  componentName: SPORT_GROUP_COMPONENTS;
}

export const TournamentsGroup = ({
  sportGroupsMemo,
  componentName,
}: TournamentsGroupProps) => {
  return sportGroupsMemo?.map(
    ({ id, name, countryId, favoriteTournaments, topTournaments }) => {
      const tournaments =
        componentName === SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT
          ? favoriteTournaments
          : topTournaments;

      const sportEventsCount = tournaments.reduce(
        (total, tournament) => total + tournament.events,
        0,
      );

      return (
        !!tournaments?.length && (
          <MultiAccordionItem
            value={id.toString()}
            key={id}
            role={MULTI_ACCORDION_ROLES.CHILD}
          >
            <MultiAccordionTrigger
              title={name}
              icon={tournaments[0]?.sportId}
              count={sportEventsCount}
              role={MULTI_ACCORDION_ROLES.CHILD}
            />
            <MultiAccordionContent role={MULTI_ACCORDION_ROLES.CHILD}>
              <LeagueMenuList
                showItemCountryFlag
                showFavoriteIcon
                sportId={id}
                countryId={countryId!}
                leagues={tournaments}
                initialLeagues={[]}
              />
            </MultiAccordionContent>
          </MultiAccordionItem>
        )
      );
    },
  );
};
