import { NavigationLink } from '~components/atoms/NavigationLink';
import { QUERY_PARAMS } from '~constants/common';
import { SPORTS } from '~constants/sport';
import { StoreSportEvent } from '~types/events';
import { IceHockeyExtraData } from '~types/sportExtraData';
import { SPORT_MENUS } from '~types/sportMenus';
import { buildSearchQuery } from '~utils/url';

import {
  BreakdownScore,
  MainScore,
  MatchStatus,
  ScoreboardActionsPanel,
  ScoreDetailsMobile,
  Title,
} from '../../../InplayEventOverview';
import { useIceHockeyScoreboard } from '../../hooks';
import { SportBackgroundBox } from '../../SportBackgroundBox';

import { FormattedScoreboardStartDate } from './components';

interface IceHockeyScoreboardProps {
  restEventData: Omit<StoreSportEvent, 'extraData'>;
  parsedExtraData: IceHockeyExtraData | null;
}

export const IceHockeyScoreboard = ({
  restEventData,
  parsedExtraData,
}: IceHockeyScoreboardProps) => {
  const {
    formattedMatchStatus,
    homeTeamName,
    awayTeamName,
    homeTeamScore,
    awayTeamScore,
    sportName,
    countryName,
    leagueName,
    time,
    scores,
    isMatchStarted,
    formattedStartDate,
    isMobileOrTablet,
  } = useIceHockeyScoreboard(restEventData, parsedExtraData);

  const { sportId, countryId, leagueId, code, countryCode } = restEventData;

  return (
    <SportBackgroundBox
      sport={sportName as SPORTS}
      isMatchStarted={isMatchStarted}
    >
      <NavigationLink
        to={{
          search: buildSearchQuery({
            [QUERY_PARAMS.SPORT_ID]: sportId,
            [QUERY_PARAMS.COUNTRY_ID]: countryId,
            [QUERY_PARAMS.LEAGUE_ID]: leagueId,
            [QUERY_PARAMS.MENU]: SPORT_MENUS.PREMATCH,
          }),
        }}
        end
      >
        <Title
          countryCode={code || (countryCode as string)}
          sportId={sportId as number}
          sport={sportName}
          country={countryName}
          league={leagueName}
        />
      </NavigationLink>
      {isMatchStarted ? (
        <MatchStatus matchStatus={formattedMatchStatus} time={time} />
      ) : (
        <FormattedScoreboardStartDate formattedStartDate={formattedStartDate} />
      )}
      <MainScore
        firstTeam={homeTeamName}
        firstScore={homeTeamScore}
        secondScore={awayTeamScore}
        secondTeam={awayTeamName}
        formattedStartDate={formattedStartDate}
        isMatchStarted={isMatchStarted}
      />
      {isMatchStarted && isMobileOrTablet && (
        <ScoreDetailsMobile sportName={sportName} extraData={parsedExtraData} />
      )}
      {isMatchStarted && !isMobileOrTablet && (
        <BreakdownScore scores={scores} />
      )}
      {isMatchStarted && <ScoreboardActionsPanel />}
    </SportBackgroundBox>
  );
};
