import { useEffect, useRef } from 'react';

import { SIGNAL_R_SOCKET_NAMES } from '~constants/signalR';
import { useNetworkStatus } from '~hooks/useNetworkStatus';
import { useAppDispatch, useAppSelector } from '~store';
import {
  startSignalRAuthedSocket,
  startSignalRSocket,
  stopSignalRAuthedSocket,
  stopSignalRSocket,
} from '~store/slices/signalRSocketsSlice';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';

export const useSignalRSockets = () => {
  const { isOnline, isReconnected, isDisconnected } = useNetworkStatus();
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const dispatch = useAppDispatch();
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    dispatch(startSignalRSocket([SIGNAL_R_SOCKET_NAMES.INPLAY]));
  }, []);

  useEffect(() => {
    if (isDisconnected) {
      dispatch(stopSignalRSocket());
    }
  }, [isDisconnected]);

  useEffect(() => {
    if (isOnline && isReconnected) {
      dispatch(startSignalRSocket([SIGNAL_R_SOCKET_NAMES.INPLAY]));
    }
  }, [isOnline, isReconnected]);

  useEffect(() => {
    if (isUserLoggedIn) {
      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        dispatch(
          startSignalRAuthedSocket([SIGNAL_R_SOCKET_NAMES.NOTIFICATIONS]),
        );
      }, 5000);
    } else {
      dispatch(stopSignalRAuthedSocket());
    }
  }, [isUserLoggedIn]);
};
