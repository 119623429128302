import { useEffect } from 'react';

import { useLazyGetBannerByGroupName } from '~api/content/contentQueries';
import { BannerGroup } from '~api/content/types';
import {
  bannerGroupNameMobile,
  bannerGroupNameWeb,
} from '~components/atoms/BannerList';
import { CHANNEL_TYPES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { setBannerData } from '~store/slices/userUISlice';
import { selectLanguage } from '~store/slices/websiteSettings';

export const useLoadBannerGroups = () => {
  const dispatch = useAppDispatch();
  const { isMobileOrTablet, isMobile, isTablet } = useMedia();
  const { lazyGetBannerByGroupNameQuery } = useLazyGetBannerByGroupName();

  const language = useAppSelector(selectLanguage);

  useEffect(() => {
    const ongoingGroup: string[] = isMobileOrTablet
      ? bannerGroupNameMobile
      : bannerGroupNameWeb;

    const loadBannerGroups = async () => {
      try {
        const channelType = isMobile
          ? CHANNEL_TYPES.MOBILE
          : isTablet
            ? CHANNEL_TYPES.TABLET
            : CHANNEL_TYPES.WEB;

        const { data: groups } = await lazyGetBannerByGroupNameQuery({
          names: ongoingGroup,
          channelType: channelType,
        });
        const groupsMapping: Record<string, BannerGroup> = {};

        if (groups) {
          ongoingGroup.map((groupName) => {
            groupsMapping[groupName] =
              groups.find(({ name }) => name === groupName) ||
              ({} as BannerGroup);
          });
          dispatch(setBannerData(groupsMapping));
        }
      } catch (error) {
        console.error('Failed getting banners', error);
      }
    };

    loadBannerGroups();
  }, [isMobile, isTablet, isMobileOrTablet, language]);
};
