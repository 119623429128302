import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { StyledNavigationContainer } from '~components/molecules/UserProfile/components/NavigationItem/styled.components';
import { useMedia, useRouterQuery } from '~hooks';
import { useIsCasinoWebsite } from '~hooks/useIsCasinoWebsite';
import { useTranslation } from '~hooks/useTranslation';

import { getNavigationItems } from '../../constants/navigationItems';

export const UserProfileNavigationMobile = () => {
  const { localized } = useTranslation();
  const { navigateWithHistory } = useRouterQuery();
  const isCasinoWebsite = useIsCasinoWebsite();
  const { isMobileOrTablet } = useMedia();

  return (
    <Box>
      {getNavigationItems({ isCasinoWebsite, isMobileOrTablet }).map(
        ({ icon: Icon, title, value }) => {
          return (
            <StyledNavigationContainer
              flexRow
              key={value}
              onClick={() => navigateWithHistory(value)}
              gap={2}
              alignCenter
              mobile
            >
              {Icon && (
                <Icon
                  className="icon"
                  style={{ width: '20px', height: '20px' }}
                />
              )}
              <Text level="14-20">{localized(title)}</Text>
            </StyledNavigationContainer>
          );
        },
      )}
    </Box>
  );
};
