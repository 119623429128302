import { useEffect } from 'react';

import { useGetLikedGames, useGetPopularGames } from '~api/games/gamesQueries';
import { useIsCasinoWebsite } from '~hooks/useIsCasinoWebsite';
import { useAppDispatch, useAppSelector } from '~store';
import { setLikedGames, setPopular } from '~store/slices/gamesSlice';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';

export const useCasinoDataLoad = () => {
  const dispatch = useAppDispatch();
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const { getPopularGamesQuery } = useGetPopularGames();
  const { getLikedGamesQuery } = useGetLikedGames();
  const isCasinoWebsite = useIsCasinoWebsite();

  const loadMostLikedGames = async () => {
    try {
      const data = await getPopularGamesQuery().unwrap();

      dispatch(setPopular(data));
    } catch (e) {
      console.log(e);
    }
  };

  const loadLikedGames = async () => {
    try {
      const data = await getLikedGamesQuery().unwrap();

      dispatch(setLikedGames(data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isCasinoWebsite) {
      loadMostLikedGames();
    }
  }, [isCasinoWebsite]);

  useEffect(() => {
    if (isUserLoggedIn && isCasinoWebsite) {
      loadLikedGames();
    }
  }, [isUserLoggedIn, isCasinoWebsite]);
};

export default useCasinoDataLoad;
