import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { CSS } from 'stitches.config';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { ShareLinkPopoverContent } from '~components/atoms/ShareLink/ShareLinkPopoverContent';
import { StyledShareIconWrapper } from '~components/atoms/ShareLink/styled.components';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import { useMedia } from '~hooks/useMedia';
import { useOuterClick } from '~hooks/useOuterClick';
import { ShareIcon } from '~icons';

import { SOCIAL_MEDIA } from './constants';

export interface SocialMediaItem {
  icon: SOCIAL_MEDIA;
  label: string;
  onClick: () => void;
}

interface ShareData {
  url: string;
  message?: string;
}

interface ShareLinkProps {
  data: ShareData;
  title?: string;
  css?: CSS;
  contentWidth?: string;
  children?: ReactNode;
}

export const ShareLink = ({
  data,
  title,
  css,
  contentWidth,
  children,
}: ShareLinkProps) => {
  const { isMobileOrTablet } = useMedia();
  const { handleShare, handleCopy, copyLinkLabel, isCopied } =
    useCopyToClipboard();
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { url, message } = data;

  useOuterClick(popoverRef, () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setIsOpen(false), 0);
  });

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  if (!url) return null;

  const createLinkHandler = (linkUrl: string) => () => {
    const link = document.createElement('a');

    link.style.display = 'none';
    document.body.appendChild(link);
    link.href = linkUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
    link.remove();
  };

  const socialMediaOptions: SocialMediaItem[] = [
    {
      icon: SOCIAL_MEDIA.WHATSAPP,
      label: 'WhatsApp',
      onClick: createLinkHandler(
        `https://wa.me/?text=${encodeURIComponent(url)}`,
      ),
    },
    {
      icon: SOCIAL_MEDIA.TELEGRAM,
      label: 'Telegram',
      onClick: createLinkHandler(
        `https://t.me/share/url?url=${encodeURIComponent(message || '')}`,
      ),
    },
    {
      icon: SOCIAL_MEDIA.FACEBOOK,
      label: 'Facebook',
      onClick: createLinkHandler(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      ),
    },
    {
      icon: SOCIAL_MEDIA.X,
      label: 'X',
      onClick: createLinkHandler(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
      ),
    },
    {
      icon: SOCIAL_MEDIA.COPY_LINK,
      label: copyLinkLabel,
      onClick: () => handleCopy(url),
    },
  ];

  const handleTriggerClickMobile = () => handleShare(data);

  const handleTriggerClickDesktop = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  return isMobileOrTablet ? (
    <StyledShareIconWrapper
      css={css}
      flexRow
      onClick={handleTriggerClickMobile}
    >
      {children || <ShareIcon />}
    </StyledShareIconWrapper>
  ) : (
    <Popover isOpen={isOpen}>
      <PopoverContent
        side="bottom"
        tone="secondary"
        css={{ m: 0, p: 0 }}
        width={contentWidth}
      >
        <ShareLinkPopoverContent
          title={title}
          ref={popoverRef}
          isCopied={isCopied}
          socialMediaOptions={socialMediaOptions}
        />
      </PopoverContent>
      <PopoverTrigger asChild>
        <StyledShareIconWrapper
          css={css}
          onClick={handleTriggerClickDesktop}
          isOpen={isOpen}
        >
          {children || <ShareIcon />}
        </StyledShareIconWrapper>
      </PopoverTrigger>
    </Popover>
  );
};
