import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserProfileData } from '~api/user/types';
import { RootState } from '~store';
import { deleteAuthCookiesData } from '~utils/cookies';

export const enum USER_DEFAULT_BALANCE {
  MAIN = 1,
  BONUS = 2,
}

export interface UserState {
  isUserProfileLoaded: boolean;
  isUserLoggedIn: boolean;
  profile: UserProfileData | null;
  bonusBalance: number;
  defaultBalance: USER_DEFAULT_BALANCE;
  isUserBlocked: boolean;
}

const initialState: UserState = {
  isUserProfileLoaded: false,
  isUserLoggedIn: false,
  profile: null,
  bonusBalance: 0,
  defaultBalance: USER_DEFAULT_BALANCE.MAIN,
  isUserBlocked: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      state.isUserLoggedIn = true;
    },
    logout: (state) => {
      state.isUserLoggedIn = false;
      state.profile = null;
      state.isUserProfileLoaded = false;
      deleteAuthCookiesData();
    },
    setProfile: (state, action: PayloadAction<UserProfileData>) => {
      state.profile = action.payload;
      state.defaultBalance = action.payload.defaultBalance;
    },
    updateProfile: (state, action: PayloadAction<Partial<UserProfileData>>) => {
      state.profile = {
        ...state.profile,
        ...action.payload,
      } as UserProfileData;
    },
    setDefaultBalance: (state, action: PayloadAction<USER_DEFAULT_BALANCE>) => {
      state.defaultBalance = action.payload;
    },
    setIsUserProfileLoaded: (state, action: PayloadAction<boolean>) => {
      state.isUserProfileLoaded = action.payload;
    },
    setIsUserBlocked: (state, action: PayloadAction<boolean>) => {
      state.isUserBlocked = action.payload;
    },
  },
});

const getUserState = (state: RootState) => state.userState;

export const selectIsUserLoggedIn = createSelector(
  [getUserState],
  (userState) => userState.isUserLoggedIn,
);

export const selectIsUserProfileLoaded = createSelector(
  [getUserState],
  (userState) => userState.isUserProfileLoaded,
);

export const selectUserProfileId = createSelector(
  [getUserState],
  (userState) => userState?.profile?.id,
);

export const selectUserProfileLanguageId = createSelector(
  [getUserState],
  (userState) => userState?.profile?.languageId,
);

export const {
  login,
  logout,
  setProfile,
  updateProfile,
  setDefaultBalance,
  setIsUserProfileLoaded,
  setIsUserBlocked,
} = userSlice.actions;

export default userSlice.reducer;
