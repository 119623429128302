import { TabsList } from '~components/atoms/Tabs';
import { useTranslation } from '~hooks/useTranslation';

import { USER_PAYMENT_TABS } from '../UserProfileDialog/constants';

export const userPaymentsTabs = [
  USER_PAYMENT_TABS.WITHDRAW,
  USER_PAYMENT_TABS.DEPOSIT,
  USER_PAYMENT_TABS.TRANSACTION_HISTORY,
];

interface UserProfileTabListProps {
  uppercase?: boolean;
  withSlider?: boolean;
  withSeparator?: boolean;
  isMobileTabs?: boolean;
}

export const UserPaymentsTabs = ({
  withSlider = false,
  isMobileTabs = false,
  uppercase,
  withSeparator = false,
}: UserProfileTabListProps) => {
  const { localized } = useTranslation();

  return (
    <TabsList
      isMobileTabs={isMobileTabs}
      withSeparator={withSeparator}
      withSlider={withSlider}
      tabs={userPaymentsTabs}
      uppercase={uppercase}
      ariaLabel={localized('userProfile.personalProfileInfo.ariaLabel')}
    />
  );
};
