import { useEffect, useRef } from 'react';

import { useNetworkStatus } from '~hooks/useNetworkStatus';
import { useAppDispatch, useAppSelector } from '~store';
import { resetLiveMenuSlice } from '~store/slices/liveMenuSlice';
import { resetPrematchSlice } from '~store/slices/prematchMenuSlice';
import {
  setEventSocketConntected,
  setEventsSocket,
  setPrimaryDataLoaded,
} from '~store/slices/signalRSocketsSlice';
import {
  ACTION_TYPE,
  getActionMessage,
  SOCKET_URL,
} from '~utils/eventsSocketUtils';

export const useLaunchEventsSocket = () => {
  const { isDisconnected, isOnline } = useNetworkStatus();

  const dispatch = useAppDispatch();
  const { eventsSocket } = useAppSelector((state) => state.signalRSockets);
  const pingInterval = useRef<NodeJS.Timeout>();

  const initSocket = () => {
    clearInterval(pingInterval.current!);
    dispatch(resetLiveMenuSlice());
    dispatch(resetPrematchSlice());
    dispatch(setPrimaryDataLoaded(false));
    dispatch(setEventSocketConntected(false));
    dispatch(setEventsSocket(new WebSocket(SOCKET_URL)));
  };

  const handleOpen = () => {
    dispatch(setEventSocketConntected(true));

    pingInterval.current = setInterval(() => {
      try {
        if (eventsSocket?.readyState === WebSocket.OPEN) {
          eventsSocket?.send(getActionMessage(ACTION_TYPE.PING));
        } else {
          initSocket();
        }
      } catch (e) {
        console.log(e);
      }
    }, 5000);
  };

  useEffect(() => {
    if (!eventsSocket) {
      initSocket();
    }
  }, [eventsSocket]);

  useEffect(() => {
    if (!eventsSocket) return;

    const handleError = () => {
      initSocket();
    };

    eventsSocket.addEventListener('open', handleOpen);
    eventsSocket.addEventListener('error', handleError);

    return () => {
      eventsSocket.removeEventListener('open', handleOpen);
      eventsSocket.removeEventListener('error', handleError);
      pingInterval.current && clearInterval(pingInterval.current);
    };
  }, [eventsSocket, isOnline]);

  useEffect(() => {
    if (isDisconnected && eventsSocket) {
      eventsSocket?.close();
    }
  }, [isDisconnected, eventsSocket]);

  useEffect(() => {
    if (eventsSocket?.readyState !== WebSocket.OPEN) {
      dispatch(setEventSocketConntected(false));
      if (eventsSocket?.readyState === WebSocket.CLOSED) {
        initSocket();
      }
    }
  }, [eventsSocket, eventsSocket?.readyState]);
};
