import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { InPlayMenuSport } from '~api/sportEvent/types';
import {
  getMinMainEventDataWidth,
  getSelectionWidth,
} from '~components/molecules/MainHighlights/helpers';
import { QUERY_PARAMS } from '~constants/common';
import { SELECTIONS_GAP } from '~constants/mainPage';
import useDimensions from '~hooks/useDimensions';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';
import { getParamFromQuery } from '~utils/url';

import { useLeagueEventsWithMarkets } from './useLeagueEventsWithMarkets';

export const useLeagueEventsMenu = () => {
  const { search } = useLocation();
  const activeLeague = getParamFromQuery(search, QUERY_PARAMS.LEAGUE_ID);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const [eventsListRef, dimensions] = useDimensions();
  const { isLaptop } = useMedia();
  const { leagueEventsData, isLoading, prematchEventsByLeagueData } =
    useLeagueEventsWithMarkets();
  const { sportId } = useQueryParams();
  const [maxMarketsCount, setMaxMarketsCount] = useState<number>(0);
  const sport = prematchEventsByLeagueData?.[0] as InPlayMenuSport;
  const { name: sportName } = sport ?? {};
  const [country] = sport?.countries ?? [];
  const { name: countryName, code: countryCode, id: countryId } = country ?? {};
  const [league] = country?.leagues ?? [];
  const { name: leagueName, id: leagueId, events } = league ?? {};

  const [openItems, setOpenItems] = useState<string[]>([]);
  const shouldRender = !!activeLeague && mainMarkets?.length > 0;

  const getMaxMarketsCount = useCallback((): number => {
    const { width } = dimensions;

    if (width) {
      const allowedMarketsWidth = width - getMinMainEventDataWidth(isLaptop);

      const SINGLE_MARKET_WIDTH =
        getSelectionWidth(isLaptop) * 3 + SELECTIONS_GAP * 2;

      const marketsCount = Math.floor(
        allowedMarketsWidth / SINGLE_MARKET_WIDTH,
      );

      return marketsCount > 3 ? 3 : marketsCount;
    }

    return 0;
  }, [dimensions, isLaptop]);

  useLayoutEffect(() => {
    const maxMarketsCount = getMaxMarketsCount();

    setMaxMarketsCount(maxMarketsCount);
  }, [dimensions, mainMarkets]);

  useEffect(() => {
    const newOpenItems = leagueEventsData?.map(({ date }) => date) ?? [];

    setOpenItems(newOpenItems);
  }, [activeLeague, leagueEventsData, leagueId]);

  return {
    isFetching: isLoading,
    countryCode,
    countryId,
    countryName,
    events,
    isLoading,
    isUserLoggedIn,
    leagueEventsData,
    leagueId,
    leagueName,
    mainMarkets,
    maxMarketsCount,
    openItems,
    shouldRender,
    sportId,
    sportName,
    setOpenItems,
    eventsListRef,
  };
};
