import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledModalHeaderWrapper = styled(Box, {
  p: '$3',
  pt: '60px',
  pb: '65px',
  backgroundColor: '$dialogBgColor',
});

export const StyledInnerBox = styled(Box, {
  borderRadius: '$8',
  display: 'flex',
  flexDirection: 'column',
  width: '288px',
  gap: '$1',
  '@xs_sm': {
    width: '100%',
    gap: '$2',
    pb: '0',
  },
});

export const StyledContentBox = styled(Box, {
  gap: '$1',
  display: 'flex',
  flexDirection: 'column',
  '@xs_sm': {
    gap: '$2',
  },
});

export const StyledProfileBox = styled(Box, {
  borderRadius: '$8',
  backgroundColor: '$grayDarkMain2',
  display: 'flex',
  flexDirection: 'column',
  gap: '$3',
  p: '$3',
});
