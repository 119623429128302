import { useState } from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { BalanceInfo } from '~components/atoms/BalanceInfo';
import { Box } from '~components/atoms/Box';
import { UserBalancesNavigationDropDown } from '~components/molecules/UserProfile/components/UserBalancesNavigationDropDown';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useRouterQuery } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { UserProfileIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';

import {
  StyledChevron,
  StyledDepositButton,
  StyledUserProfileBarContainer,
  StyledUserProfileInfoContainer,
} from '../UserProfileBar';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '../UserProfileDialog/constants';
import { UserProfileNavigationDropDown } from '../UserProfileNavigationDropDown';

interface UserProfileBarProps {
  isSmallMobileScrolled?: boolean;
}

export const UserProfileBar = ({
  isSmallMobileScrolled,
}: UserProfileBarProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobileOrTablet } = useMedia();
  const [isUserProfilePopoverOpened, setIsUserProfilePopoverOpened] =
    useState(false);
  const [isBalanceOpened, setIsBalanceOpened] = useState(false);

  const { separateBonusBalance } = useAppSelector((state) => state.settings);
  const { navigateWithHistory } = useRouterQuery();
  const { profile, isUserProfileLoaded } = useAppSelector(
    (state) => state.userState,
  );
  const { balance = 0, freeBetBalance = 0 } = profile || {};

  const handleUserProfileOpen = () => {
    navigateWithHistory(`${ROUTE_TAB_NAMES.PROFILE}/`);
  };

  const handleDepositButtonClick = () => {
    if (!isMobileOrTablet) {
      dispatch(openDialog(DIALOGS.USER_PROFILE));
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.DEPOSIT,
        }),
      );
    } else {
      navigateWithHistory(`${ROUTE_TAB_NAMES.PAYMENTS_DEPOSIT}/`);
    }
  };

  if (!profile) return null;

  return (
    <Box>
      {!!profile && (
        <Box flexRow gap={2} alignCenter>
          <StyledUserProfileBarContainer
            fullWidth={isSmallMobileScrolled}
            isOpened={isBalanceOpened}
            fullHeight
            css={{
              maxHeight: isMobileOrTablet
                ? `${separateBonusBalance ? '2.5' : '2'}rem !important`
                : `${separateBonusBalance ? '52' : '44'}rem !important`,
            }}
          >
            <StyledDepositButton
              onClick={handleDepositButtonClick}
              separateBonusBalance={separateBonusBalance}
            >
              {localized('userProfile.personalProfileInfo.deposit')}
            </StyledDepositButton>
            {separateBonusBalance ? (
              <Box css={{ padding: '0 $2' }}>
                <BalanceInfo
                  balance={balance}
                  freeBetBalance={freeBetBalance}
                />
              </Box>
            ) : (
              <UserBalancesNavigationDropDown onOpenChange={setIsBalanceOpened}>
                <StyledUserProfileInfoContainer as="span">
                  <BalanceInfo
                    balance={balance}
                    freeBetBalance={freeBetBalance}
                  />
                  {isUserProfileLoaded && (
                    <Box
                      flexRow
                      css={{
                        width: '16px',
                        height: '10px',
                        svg: {
                          width: '16px !important',
                          height: '10px !important',
                        },
                        '@xs_sm': {
                          height: '6px',
                          width: '11px',
                          svg: {
                            width: '11px !important',
                            height: '6px !important',
                          },
                        },
                      }}
                    >
                      <StyledChevron className="chevron" />
                    </Box>
                  )}
                </StyledUserProfileInfoContainer>
              </UserBalancesNavigationDropDown>
            )}
          </StyledUserProfileBarContainer>
          {isMobileOrTablet ? (
            <StyledUserProfileBarContainer
              isRounded
              isSquare
              onClick={handleUserProfileOpen}
              css={{
                background: isUserProfilePopoverOpened
                  ? `$greenOpacity`
                  : 'none',
              }}
            >
              <UserProfileIcon />
            </StyledUserProfileBarContainer>
          ) : (
            <UserProfileNavigationDropDown
              onOpenChange={(val) => setIsUserProfilePopoverOpened(val)}
            >
              <StyledUserProfileBarContainer
                isSquare
                isRounded
                css={{
                  background: isUserProfilePopoverOpened
                    ? `$greenOpacity`
                    : 'none',
                  padding: '0 !important',
                }}
              >
                <UserProfileIcon />
              </StyledUserProfileBarContainer>
            </UserProfileNavigationDropDown>
          )}
        </Box>
      )}
    </Box>
  );
};
