import { useLayoutEffect, useRef, useState } from 'react';

export const useIsTextOverflowing = () => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const spanRef = useRef<HTMLSpanElement | null>(null);

  useLayoutEffect(() => {
    // Check if the span element is overflowing
    const spanElement = spanRef.current;

    if (spanElement) {
      setIsOverflowing(spanElement.scrollWidth > spanElement.clientWidth);
    }
  }, []);

  return { isOverflowing, spanRef };
};
