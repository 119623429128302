import React, { useMemo } from 'react';
import { CSS, styled } from 'stitches.config';

import { useTranslation } from '~hooks/useTranslation';

import {
  StyledTabsList,
  StyledTabsRoot,
  StyledTabsTrigger,
} from './styled.components';

interface TabsProps {
  children?: React.ReactNode;
  value?: string;
  onValueChange?: (value: string) => void;
  defaultTab?: string;
  fullHeight?: boolean;
}

interface TabsListProps {
  tabs: (string | number)[];
  ariaLabel?: string;
  capitalize?: boolean;
  uppercase?: boolean;
  css?: CSS;
  withSlider?: boolean;
  withSeparator?: boolean;
  isMobileTabs?: boolean;
}

const Separator = styled('div', {
  width: '1px',
  height: '16px',
  flexShrink: '0',
  borderRadius: '2px',
  background: '$grayDark',
});

export const Tabs = ({
  defaultTab,
  children,
  fullHeight,
  onValueChange,
}: TabsProps) => (
  <StyledTabsRoot
    fullHeight={fullHeight}
    defaultValue={defaultTab}
    onValueChange={onValueChange}
  >
    {children}
  </StyledTabsRoot>
);

export const TabsList = ({
  tabs,
  isMobileTabs,
  ariaLabel,
  capitalize,
  uppercase,
  css,
  withSeparator,
  withSlider,
}: TabsListProps) => {
  const { localized } = useTranslation();
  const tabsList = useMemo(
    () =>
      tabs.map((tab, index) => (
        <React.Fragment key={tab}>
          <StyledTabsTrigger
            isMobileTabs={isMobileTabs}
            value={tab.toString()}
            capitalize={capitalize}
            uppercase={uppercase}
          >
            {localized(tab.toString())}
          </StyledTabsTrigger>
          {withSeparator && index < tabs.length - 1 && <Separator />}
        </React.Fragment>
      )),
    [tabs, withSeparator, capitalize, uppercase],
  );

  return (
    <StyledTabsList
      isMobileTabs={isMobileTabs}
      aria-label={ariaLabel}
      css={css}
      withSlider={withSlider}
    >
      {tabsList}
    </StyledTabsList>
  );
};
